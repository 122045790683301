import React, { useMemo, useCallback } from "react";
import { SelectInput } from "../../../common/basic components/selectInput";
import { useAppDispatch } from "../../../redux/redux-hooks";
import { updateUserDetails } from "../../../redux/reducer/stepperSubProductSlice";
import Strings from "../../../theme/string";
import { DesignationFieldProps } from "../../../interfaces/reviewStepper.interface";
import { DESIGNATION_LIST } from "../../../common/constant/customConstant";

const DesignationField = ({
  stepperDetails,
  reviewFlag = false,
}: DesignationFieldProps) => {
  const dispatch = useAppDispatch();
  const { stepperUserDetails } = stepperDetails;
  const title = stepperUserDetails?.title;

  const options = useMemo(
    () => DESIGNATION_LIST,
    []
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value;
      const value = reviewFlag ? title?.value || "" : selectedValue;
      const isValidateFlag = selectedValue !== "Select";
      const isValidate = !reviewFlag ? isValidateFlag : title?.isValidate;
      const isdraftValidate = reviewFlag
        ? isValidateFlag
        : title?.isdraftValidate ?? true;

      dispatch(
        updateUserDetails({
          fieldName: "title",
          value,
          draftValue: selectedValue,
          isValidate,
          isdraftValidate,
        })
      );
    },
    [dispatch, title, reviewFlag]
  );

  const isError = useMemo(() => {
    return (
      stepperDetails.buttonValidatonIsRequired &&
      !(reviewFlag ? title?.isdraftValidate : title?.isValidate)
    );
  }, [stepperDetails, reviewFlag, title]);

  return (
    <div>
      <SelectInput
        labelTitle={!reviewFlag && "Title"}
        id="userNameTitle"
        required
        className="rounded-0"
        onChange={handleChange}
        selected={title?.value || ""}
        options={options}
        isError={isError}
        errorMsg={Strings.pleaseSelectAppropriateTitle}
      />
    </div>
  );
};
export default DesignationField;
