import axios from "axios";
import dashboardDiscoverMore from "../../src/assest/json/dashboardDiscoverMore.json";
import footerDetailsData from "../../src/assest/json/footerDetails.json";
import fscsProtectionAndTerms from "../../src/assest/json/fscsProtectionAndTerms.json";
import navigationBarData from "../../src/assest/json/headerNavigation.json";
import lifestyleData from "../../src/assest/json/lifeStyleProduct.json";
import linkBankAccount from "../../src/assest/json/linkBankAccount.json";
import middlePage from "../../src/assest/json/missionAndPromiseDetails.json";
import middlePageTop from "../../src/assest/json/productDetails.json";
import reviewStepperData from "../../src/assest/json/reviewStepperData.json";
import productCategoryData from "../../src/assest/json/savingAccountProduct.json";
import stepperSubProductData from "../../src/assest/json/stepperChoosePlan.json";
import stepperSummaryBoxPrivatPolicy from "../../src/assest/json/stepperSummaryBoxPrivacyPolicy.json";
import verifyCustomerCmsData from "../../src/assest/json/verifyCustomerCmsData.json";
import welcomeBoards from "../../src/assest/json/welcomeBoards.json";
import employmentDetails from "../assest/json/employmentDetails.json";
import Strings from "../theme/string";
import * as URL from "./url";

export const fetchFromApi = async (url: any, actualUrl: boolean, isToken = false) => {
  const token = localStorage.getItem("tokenVal") ?? "";
  const tmpURL = actualUrl ? url : URL.BASE_URL + url;
  let data;
  await axios
    .get(tmpURL,isToken ? {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }:{})
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  return data;
};

export const patchToAPI =async(URL: any, body: any)  =>{
  const token = localStorage.getItem("tokenVal") ?? "";
  let res = false;
  await axios.patch((URL),
      body,
      {
          headers: {
              "Content-Type": "application/json",
              "Authorization": `${token}`,
          }
      }
  ).then((response) => {
      if(response.status == Strings.twoHundred)
        res = true;
      else
        console.error("Unable to procced due to this issue -> ", response);

  }).catch((error) => {
      console.error("AXIOS ERROR: ", error);
    })
    return res;
}

export const getLifeStyleDetails = () => {
  // return fetchFromApi(URL.LIFESTYLE);
  return lifestyleData;
};

export const getNavigationDetails = (arg: any) => {
  // return fetchFromApi(URL.NAVIGATION_BAR+[arg])
  return navigationBarData;
};

export const getWelcomeBoardDetails = (arg: any) => {
  return welcomeBoards;
};

export const getMiddlePageBottomDetails = () => {
  // return fetchFromApi(URL.MIDDLEPAGE)
  return middlePage;
};

export const getMiddlePageTopDetails = () => {
  // return fetchFromApi(URL.MIDDLEPAGETOP)
  return middlePageTop;
};

export const getStepperSubProductDetails = () => {
  // return fetchFromApi(URL.STEPPERSUBPRODUCT)
  return stepperSubProductData;
};

export const getStepperSummaryBoxPrivacyPolicyDetails = () => {
  // return fetchFromApi(URL.STEPPERSUMMARYBOXPRIVACYPOLICY)
  return stepperSummaryBoxPrivatPolicy;
};

export const getSavingAccountAllProducts = () => {
  return fetchFromApi(URL.SAVING_ACCOUNT_ALL_PRODUCTS, false);
};

export const getSelectedProductSummaryData = () => {
  //API to be called to get product data from CMS
  return stepperSummaryBoxPrivatPolicy;
};

export const getProductCategoryData = () => {
  return productCategoryData;
};

export const getCountryListDetails = () => {
  return fetchFromApi(URL.COUNTRY_URL, true);
};

export const getFscsProtectionAndTermsData = () => {
  //API to be called to get FSCS protection and terms & conditions data from CMS
  return fscsProtectionAndTerms;
};

export const getLinkBankAccountData = () => {
  //API call to get description data from CMS
  return linkBankAccount;
};

export const getFindAddress = (id: string) => {
  const tmpURL = `https://ws.postcoder.com/pcw/autocomplete/find?apikey=PCW26-9GTD6-5N8MD-WP5GH&query=${id}&country=uk&format=json`;
  return fetchFromApi(tmpURL, true);
};

export const getAddressBasedOnID = (id: string) => {
  const tmpURL = `https://ws.postcoder.com/pcw/autocomplete/retrieve/?id=${id}&country=uk&apikey=PCW26-9GTD6-5N8MD-WP5GH&lines=3&exclude=organisation,country`;
  return fetchFromApi(tmpURL, true);
};

export const getEmploymentData = () => {
  //API call to get description data from CMS
  return employmentDetails;
};

export const getIndustriesListDetails = () => {
  return fetchFromApi(URL.INDUSTRIES_URL, true);
};

export const getVerifyCustomerData = () => {
  //API to be called to get verify screen data from CMS
  return verifyCustomerCmsData;
};

export const getFooterDetailsData = () => {
  return footerDetailsData;
};

export const getStepperReviewData = () => {
  return reviewStepperData;
};

export const getDashboardDiscoverMore = () => {
  return dashboardDiscoverMore;
};

export const getWithdrawalAccount = (hashedAccount : string) => {
  const url = URL.WITHDRAWAL_NOTICE_ACCOUNT + hashedAccount;
  return fetchFromApi(url, true, true)
}