import React from "react";
import { Img } from "./img";
import InformationImg from "../../assest/vector_information.svg"

export const Checkbox = (props : any) => {
  const {
    id,
    inputClassName,
    labelClassName = "",
    onChange,
    name,
    isChecked,
    isDisabled,
    value,
    label,
    informationTrue,
    onClickPopupHandler
  } = props;
  return (
    <div className="form-check customcheckbox">
      <input
        type="checkbox"
        className={`form-check-input ${inputClassName}`}
        id={id}
        data-testid={id}
        onChange={onChange}
        name={name}
        checked={isChecked}
        disabled={isDisabled}
        value={value}
      />
      {informationTrue ? <>
      <label htmlFor={id} className={`form-check-label ${labelClassName} checkBoxLabel`}>
        {label} 
      </label>  <Img src={InformationImg} onClick={onClickPopupHandler}/> </>:
      <label htmlFor={id} className={`form-check-label ${labelClassName} checkBoxLabel`}>
        {label} 
      </label>}
    </div>
  );
};