import React, { useState } from "react";
import { Checkbox } from "../../common/basic components/checkBox";
import { ListUlLi } from "../../common/basic components/listUlLi";
import { updateChoosePlanConsent } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";

export const SubProductSelectedPlanDescription = (props: any) => {
  const { tmpProduct } = props;
  const dispatch = useAppDispatch();

  const stepperDetails: any = useAppSelector(
    (state: any) => state.stepperDetails
  );
  const data = stepperDetails?.stepperSubProduct;
  const descriptionValue = data?.description;

  const onChangeHandler = () => {
    dispatch<any>(updateChoosePlanConsent(!stepperDetails.choosePlanConsent));
  };

  return (
    <div className="stepper-summary">
      <hr className="stepper-hr" />
      <div className="stepper-sub-product-title">
        {data?.title.replace("##PRODUCT_NAME##", tmpProduct.productName)}
      </div>
      {tmpProduct?.interestRate || tmpProduct?.aer ? (
        <div className="stepper-sub-product-value">
          {tmpProduct?.interestRate && tmpProduct?.aer
            ? data?.value
                .replace("##INTEREST_RATE##", tmpProduct?.interestRate)
                .replace("##AER_RATE##", tmpProduct?.aer)
            : tmpProduct?.interestRate
            ? data?.value.replace("##INTEREST_RATE##", tmpProduct?.interestRate)
            : data?.value.replace("##AER_RATE##", tmpProduct?.aer)}
        </div>
      ) : (
        <div className="stepper-sub-product-value">{data?.value}</div>
      )}
      <ListUlLi {...{ descriptionValue }} />
      <div className="tc-consent-parent">
        <div className="row">
          <div className="col-md-8 col-lg-6">
            {data?.extraValidation?.map((item: any) => (
              <div
                className="terms-conditions-choosePlan openLinkItems"
                onClick={() => window.open("https://www.monument.co")}
              >
                <span>
                  {item.replace("##PRODUCT_NAME##", tmpProduct.productName)}
                </span>
                <span>
                  <img
                    src={require(`../../assest/OpenLinkRightChevron.png`)}
                    className="open-link-right-rotated-icon"
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <div className="terms-conditions-choosePlan consent-choosePlan">
              <Checkbox
                onChange={() => onChangeHandler()}
                label={data.checkInput}
                inputClassName={"checkbox-width-zero"}
                isChecked={stepperDetails.choosePlanConsent}
                labelClassName={
                  stepperDetails.choosePlanConsent && "text-primary_one"
                }
              />
            </div>
            {stepperDetails?.buttonValidatonIsRequired && !stepperDetails.choosePlanConsent &&(
                <div className="text-danger">
                  {Strings.pleaseSelectToContinue}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
