import Select, { components, MultiValueProps, OptionProps } from "react-select";
import searchImg from "../../assest/vector_search_img.svg";
import Strings from "../../theme/string";
import { Img } from "../basic components/img";
import { Country } from "./searchCheckBoxInterface";

interface SearchCheckBoxComponentProps {
  options: any;
}
const CustomOption = (props: OptionProps<Country, true>) => {
  const { data, isSelected, innerRef, innerProps } = props;
  return (
    <components.Option {...props}>
      <div
        ref={innerRef}
        {...innerProps}
        className="select-drop-down"
      >
        {data.label}
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          className="margin-left-10"
        />
      </div>
    </components.Option>
  );
};
const CustomMultiValue = (props: MultiValueProps<Country>) => {
  return null;
};
const SearchCheckBox = ({ options, setSelectedOptions, selectedOptions }: {options : any, [x:string]:any} ) => {
  
  
  const handleChange = (selected: Country[] | any) => {
    setSelectedOptions(selected || []);
  };
  const handleRemoveOption = (optionToRemove: Country) => {
    setSelectedOptions(
      selectedOptions.filter((option : any) => option.value !== optionToRemove.value)
    );
  };
  return (
    <div>
      <Select
        isMulti
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
        placeholder={
          <><Img src={searchImg} />
          <span>
            <i className="bi bi-search"></i> {Strings.typeToSearch}
          </span></>
        }
        options={options}
        className="select-box-country"
        value={selectedOptions}
        onChange={handleChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: "green",
            primary25: "#90EE90",
          },
        })}
      />
      <div className="margin-top-5">
        {selectedOptions?.length > 0 && (
          <div>
            {selectedOptions.map((option : any) => (
              <span
                key={option.value}
                className="select-input-box"
              >
                {option.label}
                <div
                  className="display-flex close"
                  aria-label="Close"
                  onClick={() => handleRemoveOption(option)}
                >
                  <span
                    aria-hidden="true"
                    className="select-span"
                  >
                    &times;
                  </span>
                </div>
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchCheckBox;