import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { ListUlLi } from "../../common/basic components/listUlLi";
import { getSelectedProductSummaryData } from "../../services/appApi";
import { MONUMENT_BASE_URL } from "../../services/url";
import { HowWeUseDataModal } from "./howWeUseDataModal";
import { updateCheckList } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../redux/redux-hooks";
import Strings from "../../theme/string";

interface SummaryData {
  faqData?: {
    faqId?: number;
    faqQuestion?: string;
    faqAnswer?: string;
  };
  otherData?: {
    summaryHeading?: string;
    timerHeading?: string;
    timerSubheading?: string;
    timerArray?: object;
    timerInformation?: string;
  };
}

const StepperSummaryBox = () => {
  const [summaryData, setSummaryData] = useState<SummaryData | any>();
  const [isPopUpEnabled, setIsPoupEnabled] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    apiHandler();
  }, []);

  const apiHandler = async () => {
    let apiData = getSelectedProductSummaryData();
    if (apiData) {
      setSummaryData(apiData);
    } else {
      console.error("Error fetching summary box data!");
    }
  };

  const privacyFlagHandler=()=>{
    dispatch(updateCheckList({obj : {value : true,date:new Date().toISOString()}, fieldName : Strings?.privacyFlag}))
  }

  return (
    <>
      {isPopUpEnabled && (
        <HowWeUseDataModal
          show={true}
          onClick={() => setIsPoupEnabled(false)}
          data={summaryData?.modalData}
        />
      )}

      {summaryData?.otherData && (
        <div className="mb-4 d-flex justify-content-between">
          <div>{summaryData?.otherData?.summaryHeading}</div>
          <a
            className="download-icon-open-link"
            onClick={() => window.open(MONUMENT_BASE_URL)}
          >
            <img
              alt={"download icon"}
              src={require(`../../assest/download-icon.png`)}
            />
          </a>
        </div>
      )}
      {summaryData?.faqData && (
        <Accordion flush className="border-top border-bottom">
          {summaryData?.faqData?.map((faqItem: any) => {
            return (
              <Accordion.Item eventKey={faqItem?.faqId} key={faqItem?.faqId}>
                <Accordion.Header>{faqItem?.faqQuestion}</Accordion.Header>
                <Accordion.Body className="new-line">
                  {faqItem?.faqAnswer}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
      {summaryData?.otherData && (
        <div className="mt-4 summary-bottom-area-section">
          <h5 className="card-title">{summaryData?.otherData?.timerHeading}</h5>
          <div className="mt-2">{summaryData?.otherData?.timerSubheading}</div>
          <ListUlLi descriptionValue={summaryData?.otherData?.timerArray} />
          <div className="d-flex justify-content-start" onClick={privacyFlagHandler}>
            <div>
              <img
                alt={"information icon"}
                src={require(`../../assest/info-circle-fill.png`)}
                onClick={() => setIsPoupEnabled(true)}
              />
            </div>
            <a
              href="javascript:void(0)"
              onClick={() => setIsPoupEnabled(true)}
              className="infoTextSummaryWithIcon"
            >
              {summaryData?.otherData?.timerInformation}
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default StepperSummaryBox;
