import React, { useState } from "react";
import usInformationData from "../../../assest/json/usInformationPopup.json";
import { Checkbox } from "../../../common/basic components/checkBox";
import { InformationDisplayPopup } from "../../../common/components/InfomationDisplayPopup";
import SearchCheckBox from "../../../common/components/searchCheckBox";
import { TaxDisplayComponent } from "../../../common/components/stepper/taxDisplayComponent";
import {
  ARRAY_INITIAL_COUNT,
  INITIAL_COUNT,
  NINE_COUNTER,
} from "../../../common/constant/customConstant";
import { updateCitizenAndTax } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../../redux/redux-hooks";
import styles from "../../../styleModules/reviewStepper.module.scss";
import Strings from "../../../theme/string";
import { EditButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { SaveButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";
import StringData from "../../choosePlan/stringCitizenShipNdTaxInformation";

export const CitizenShipTaxDisplay = (props: any) => {
  const {
    mainObj,
    editFlag,
    Country,
    stepperDetails,
    isEditBtnRequired,
    isExtraFiledRequired,
  } = props;
  const dispatch = useAppDispatch();
  const {
    isTaxInputEnableForAnother,
    isTaxInputEnableForUK,
    isTaxInputEnableForUS,
  } = mainObj;
  const [isPopupEnable, setIsPoupEnabled] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);

  const setOtherThanUKCitizenHandler = (item: any, tmpFieldName: string) => {
    dispatch<any>(
      updateCitizenAndTax({
        obj: {
          isValidate: item.length > INITIAL_COUNT ? true : false,
          value: item,
        },
        fieldName: tmpFieldName,
      })
    );
  };

  const isCheckBoxChecked = () => {
    if (!stepperDetails?.buttonValidatonIsRequired) return false;
    if (
      stepperDetails?.buttonValidatonIsRequired &&
      (mainObj?.isTaxInputEnableForAnother?.value ||
        mainObj?.isTaxInputEnableForUK?.value ||
        mainObj?.isTaxInputEnableForUS?.value)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateOtherCountryTaxField = (val: any, countryName: string) => {
    let tmpObj: any[] = [];
    if (mainObj?.taxNumber?.length > INITIAL_COUNT) {
      tmpObj = [...mainObj.taxNumber];
      const tmpIndex = mainObj.taxNumber.findIndex(
        (item: any) => item?.countryName == countryName
      );
      if (tmpIndex != ARRAY_INITIAL_COUNT) {
        tmpObj.splice(tmpIndex, 1);
      }
    } else {
      tmpObj =
        mainObj?.taxNumber && mainObj?.taxNumber.length > INITIAL_COUNT
          ? [...mainObj.taxNumber]
          : [];
    }
    tmpObj.push({
      countryName,
      value: val,
      isValidate: val.length == NINE_COUNTER ? true : false,
    });
    dispatch<any>(
      updateCitizenAndTax({ obj: tmpObj, fieldName: Strings.taxNumber })
    );
  };

  const updateTaxCountry = () => {
    let arr: any[] = [];
    const tmpObj = StringData?.selectTaxRecidency?.checkInputs;
    if (isTaxInputEnableForUK?.value)
      arr.push(<div>{tmpObj[Strings.zero]?.label}</div>);
    if (isTaxInputEnableForAnother?.value)
      arr.push(<div>{tmpObj[Strings.one]?.label}</div>);
    if (isTaxInputEnableForUS?.value)
      arr.push(<div>{tmpObj[Strings.two]?.label}</div>);
    return arr;
  };

  const updateTaxResidencyHandler = (key: any) => {
    if (key == Strings.us.toLowerCase()) {
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: !mainObj?.isTaxInputEnableForUS?.value },
          fieldName: Strings.isTaxInputEnableForUS,
        })
      );
    }
    if (key === Strings.another) {
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: !mainObj?.isTaxInputEnableForAnother?.value },
          fieldName: Strings.isTaxInputEnableForAnother,
        })
      );
    }
    if (key === Strings.uk) {
      dispatch<any>(
        updateCitizenAndTax({
          obj: { value: !mainObj?.isTaxInputEnableForUK?.value },
          fieldName: Strings.isTaxInputEnableForUK,
        })
      );
    }
  };

  const editModeHandler = () => {
    setDataSaved(false);
    setIsEditEnabled(true);
  };

  const saveModeHandler = () => {
    setDataSaved(true);
    setIsEditEnabled(false);
  };

  const getTaxNumberForCountry = (val: string) => {
    const obj = mainObj?.taxNumber?.find((item: any) => {
      return item.countryName == val;
    });
    return obj?.value;
  };

  const isTaxNumberField = (tmpVal: string) => {
    let isValidate = mainObj?.taxNumber?.find((item: any) => {
      return item.countryName == tmpVal && item.isValidate;
    });
    if (isValidate) return false;
    else return true;
  };

  const isTaxNumberFieldHasValue = (tmpVal: string) => {
    let isValidate = mainObj?.taxNumber?.findIndex((item: any) => {
      return item.countryName == tmpVal && item.value;
    });
    return isValidate > ARRAY_INITIAL_COUNT ? true : false;
  };

  const enableNdDisableField = () => {
    if (dataSaved && isEditEnabled && editFlag) return true;
    if (dataSaved) return true;
    if (editFlag && !isEditEnabled) return true;
    if (dataSaved && editFlag) return true;
  };

  return (
    <>
      {isPopupEnable && (
        <InformationDisplayPopup
          show={isPopupEnable}
          onClick={() => setIsPoupEnabled(false)}
          data={usInformationData}
        />
      )}

      <div className="d-flex align-items-center gap-3 mt-3">
        {editFlag && (
          <label className="form-label">
            {" "}
            {Strings.taxResidency}
            <span className="text-danger"> * </span>
          </label>
        )}
        {isEditEnabled && (
          <SaveButtonComponent onClick={() => saveModeHandler()} />
        )}
      </div>
      {enableNdDisableField() && (
        <div className="col-md-8 col-lg-6">
          <div className="d-flex">
            <div
              id={`formField-isUKCountry`}
              className={styles.genericFormFieldStyle}
              aria-label={`isUKCountry field`}
            >
              <div className={styles.genericFormFieldTextStyle}>
                {updateTaxCountry()}
              </div>
            </div>
            {isEditBtnRequired && (
              <EditButtonComponent onClick={() => editModeHandler()} />
            )}
          </div>
        </div>
      )}

      {!enableNdDisableField() && (
        <div className="row mb-3">
          <div className="col-md-8 col-lg-6">
            <label className="form-label">
              {StringData.selectTaxRecidency.title}{" "}
              <span className="text-danger"> * </span>
            </label>
            {StringData?.selectTaxRecidency?.checkInputs.map((item: any) => (
              <div
                className="terms-conditions-choosePlan search-input"
                key={item.country}
              >
                <Checkbox
                  label={item.label}
                  informationTrue={
                    item &&
                    item?.country?.toLowerCase().search(Strings.us) >=
                      INITIAL_COUNT
                  }
                  inputClassName={"checkbox-width-zero"}
                  labelClassName={"text-color-green"}
                  isChecked={mainObj?.[item.checked]?.value}
                  onChange={() => updateTaxResidencyHandler(item.country)}
                  onClickPopupHandler={() => setIsPoupEnabled(true)}
                />
              </div>
            ))}
            {isCheckBoxChecked() && (
              <div className="text-danger">
                {Strings.mustSelectAtLeastOneOption}
              </div>
            )}
          </div>
        </div>
      )}
      {enableNdDisableField() && (
        <>
          <div className="row mt-3 col-md-8 col-lg-6">
            <label className="form-label">
              Other countries you are tax resident of{" "}
            </label>
          </div>
          <div className="d-flex">
            <div className="col-md-8 col-lg-6">
              <div className={styles.genericFormFieldStyle}>
                <div className={styles.genericFormFieldTextStyle}>
                  {mainObj?.otherThanUKTaxNumber?.value.map(
                    (item: any) => item.label + " "
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!enableNdDisableField() &&
        mainObj?.isTaxInputEnableForAnother?.value && (
          <div className="row mb-3">
            <div className="col-md-8 col-lg-6">
              <label className="form-label">
                {StringData.selectTaxCountry}{" "}
                <span className="text-danger"> * </span>
              </label>
              <SearchCheckBox
                options={Country}
                setSelectedOptions={(item: any) =>
                  setOtherThanUKCitizenHandler(
                    item,
                    Strings.otherThanUKTaxNumber
                  )
                }
                selectedOptions={mainObj?.otherThanUKTaxNumber?.value}
              />
            </div>
          </div>
        )}
      {!enableNdDisableField() &&
        (stepperDetails?.buttonValidatonIsRequired || editFlag) &&
        mainObj?.isTaxInputEnableForAnother?.value &&
        !mainObj?.otherThanUKTaxNumber?.isValidate && (
          <div className="text-danger">{Strings.defaultFieldCanNotBeBlank}</div>
        )}
      {!enableNdDisableField() &&
        mainObj?.isTaxInputEnableForAnother?.value &&
        mainObj?.otherThanUKTaxNumber?.isValidate &&
        mainObj?.otherThanUKTaxNumber?.value.map((item: any) => {
          return (
            <>
              <TaxDisplayComponent
                key={item?.label}
                label={`${Strings.taxNumberFor} ${item.label}`}
                placeholder={Strings.enterTextNumber}
                bottomText={Strings.taxFormatExample}
                updateCountryHandler={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => updateOtherCountryTaxField(e, item?.label)}
                value={getTaxNumberForCountry(item?.label)}
              />
              {(stepperDetails?.buttonValidatonIsRequired || editFlag) &&
                isTaxNumberField(item?.label) && (
                  <div className="text-danger">
                    {isTaxNumberFieldHasValue(item?.label)
                      ? Strings.yourTaxNumberMustBeNineDigit
                      : Strings.defaultFieldCanNotBeBlank}
                  </div>
                )}
            </>
          );
        })}

      {!enableNdDisableField() && mainObj?.isTaxInputEnableForUS?.value && (
        <TaxDisplayComponent
          label={`${Strings.taxNumberFor} US`}
          placeholder={Strings.enterTextNumber}
          bottomText={Strings.taxFormatExample}
          updateCountryHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateOtherCountryTaxField(e, Strings.us)
          }
          value={getTaxNumberForCountry(Strings.us)}
        />
      )}
      {(stepperDetails?.buttonValidatonIsRequired || editFlag) &&
        mainObj?.isTaxInputEnableForUS?.value &&
        isTaxNumberField(Strings.us) && (
          <div className="text-danger">
            {isTaxNumberFieldHasValue(Strings.us)
              ? Strings.yourTaxNumberMustBeNineDigit
              : Strings.defaultFieldCanNotBeBlank}
          </div>
        )}
    </>
  );
};
