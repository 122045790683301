import { createSlice } from "@reduxjs/toolkit";
import { getStepperSubProductAction } from "./../actions/stepperSubproductAction";

const initialState = {
  stepperSubProduct: [],
  stepperUserDetails : {},
  stepperChoosedPlan : {},
  citizenshipAndTaxSection: {
    isTaxInputEnableForUS : {value : false},
    otherThanUKCitizen :{
      "isValidate": true,
      "value": [
          {
              "isoCode": "GBR",
              "label": "United Kingdom",
              "value": "GBR"
          }
      ]
  }
  },
  choosePlanConsent : false,
  reviewConfirmation:false,
  buttonValidatonIsRequired : false,
  stepperLinkUKBank : {},
  employmentInformationSection : {},
  stepperCheckList:{
    privacyFlag:{value:false},
    reviewConfirmation:{value:false},
    termsAndConditionFlag:{value:false},
    fscsConsentFlag:{value:false},
  }
};

export const stepperSubProductSlice = createSlice({
  name: "StepperSubProduct",
  initialState,
  reducers: {
    clearStates: () => initialState,

    updateUserDetails: (state,{payload}) => {
      const tmpObj = {[payload.fieldName] : payload} 
      state.stepperUserDetails = {...state.stepperUserDetails, ...tmpObj}
    },

    updateChoosePlan: (state, {payload}) =>{
      state.stepperChoosedPlan = payload;
    },

    updateChoosePlanConsent: (state, {payload}) =>{
      state.choosePlanConsent = payload;
    },

    updateReviewConfirmation: (state, {payload}) =>{
      state.reviewConfirmation = payload;
    },

    updateCitizenAndTax : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload.obj} 
      state.citizenshipAndTaxSection = {...state.citizenshipAndTaxSection, ...tmpObj};
    },

    updateValidationIsRequired : (state, {payload}) =>{
      state.buttonValidatonIsRequired = payload;
    },

    updateDraftValueAsDefault : (state, {payload}) =>
    {
      state.stepperLinkUKBank = payload;
    },

    updatedLinkUKBank : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload} 
      state.stepperLinkUKBank = {...state.stepperLinkUKBank, ...tmpObj}
    },

    updateEmployeeDetails : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload} 
      state.employmentInformationSection = {...state.employmentInformationSection, ...tmpObj}
    },

    updateCheckList : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload.obj} 
      state.stepperCheckList = {...state.stepperCheckList, ...tmpObj};
    },
  }, 
  extraReducers: (builder) => {
    builder.addCase(getStepperSubProductAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.stepperSubProduct = payload
    });
  }
});

export const { clearStates, updateUserDetails, updatedLinkUKBank, updateChoosePlan, updateChoosePlanConsent, updateCitizenAndTax, updateValidationIsRequired, updateEmployeeDetails,updateReviewConfirmation, updateDraftValueAsDefault,updateCheckList } =
stepperSubProductSlice.actions;

export default stepperSubProductSlice.reducer;