import React, { useCallback, useEffect, useMemo, useState } from "react";
import LoadingModal from "../../common/components/LoadingModal";
import styles from "../../styleModules/withdrawMoney.module.scss";
import Strings from "../../theme/string";
import { SelectInput } from "../../common/basic components/selectInput";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { AccountData } from "../../interfaces/withdrawalSection.interface";
import headingStyles from "../../styleModules/commonHeading.module.scss";
import MonuUnion from "../../assest/monu_union.svg";
import {
  CURRENCY_DEFAULT_VALUE,
  CURRENT_SYMBOL,
} from "../../common/constant/customConstant";
import { formatBalance } from "../../common/utils/formatBalance";
import {
  transferFromNtToOthers,
  withdrawalEasyAccessAccount,
} from "../../services/fetchFinancialAccountData";
import {
  WITHDRAWAL_AMOUNT_EASY_ACCESS_ACCOUNT,
  WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT,
} from "../../services/url";
import { useAppSelector } from "../../redux/redux-hooks";
import { AccountTypes } from "../../enums/customerService.enums";
import { currencyToNumString } from "../../common/utils/currencyConvertors";
import { generateRandomAlphanumeric } from "../../common/utils/randomUniqueAlphanumStringGenerator";
import { Img } from "../../common/basic components/img";
import * as dashboardConstants from "../../common/constant/dashboardConstants";
import {
  MoveMoneyError,
  ApiRequest,
  SelectedData,
} from "../../interfaces/moveMoney.interface";
import useFundingPeriodValidation from "../../hooks/useFundingPeriodValidation";
import Breadcrumbs from "../../common/basic components/Breadcrumbs";

const MoveMoneyComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [moveMoneyFlag, setMoveMoneyFlag] = useState(false);
  const { accountNumber } = useParams<{ accountNumber: string }>();
  const [selectedData, setSelectedData] = useState<AccountData>();
  const [loadModalFlag, setLoadModalFlag] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredAccData, setFilteredAccData] = useState<AccountData[]>([]);
  const [filteredAccDataTo, setFilteredAccDataTo] = useState<AccountData[]>([]);
  const [selectedOptionFrom, setSelectedOptionFrom] = useState<string>("");
  const [selectedFromValue, setSelectedFromValue] = useState<any>({});
  const [selectedFromData, setSelectedFromData] = useState<AccountData>();
  const [selectedToValue, setSelectedToValue] = useState<any>({});
  const [fromError, setFromError] = useState("");
  const [error, setError] = useState<MoveMoneyError>({});
  const [dynamicDescriptionAccountName, setDynamicDescriptionAccountName] =
    useState<string>("");
  const [transferAmount, setTransferAmount] = useState<string>("");
  const [transferSubmissionError, setTransferSubmissionError] =
    useState<boolean>(false);
  const [authToken, setAuthToken] = useState("");
  const allAccounts: AccountData[] = useAppSelector(
    (state: { dashboardAccountData: { data: AccountData[] } }) =>
      state?.dashboardAccountData?.data
  );
  const accountData = allAccounts.filter(
    (account: any) =>
      account.accountStatus === dashboardConstants.active ||
      account.accountStatus === dashboardConstants.approved
  );
  const { isFundingPeriodValid, fundingPeriod } =
    useFundingPeriodValidation(selectedToValue);

  const formatCurrencyWithDecimals = useCallback((value: string) => {
    const cleanedValue = value.replace(/[^\d.]/g, "");
    const numberValue = parseFloat(cleanedValue);
    if (isNaN(numberValue)) return CURRENCY_DEFAULT_VALUE;
    return numberValue.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  }, []);
  const getDynamicAccountDescription = useCallback(
    (accStr: string) =>
      accStr.includes(AccountTypes.EASY_ACCESS_COMPLETE)
        ? AccountTypes.EASY_ACCESS_COMPLETE
        : AccountTypes.NOTICE_ACCOUNT_COMPLETE,
    []
  );
  const selectFromInputHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { selectedIndex } = e.target;
    setSelectedFromValue(fromOptions[selectedIndex].value);
    setSelectedFromData(filteredAccData[e.target.selectedIndex]);
    if (e.target.selectedIndex > 0) {
      const accNumber = filteredAccData?.filter(
        (item: AccountData, index: number) =>
          index == e.target.selectedIndex - 1
      );
      if (accNumber) {
        setDynamicDescriptionAccountName(
          getDynamicAccountDescription(accNumber[0]?.accountName) ?? ""
        );
      }
    } else {
      setDynamicDescriptionAccountName("");
    }
    setFromError("");
    setTransferSubmissionError(false);
    setError((privError) => ({
      ...privError,
      fromError: "",
      noticeFundingError: "",
    }));
  };
  const selectToInputHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { selectedIndex } = e.target;
    setSelectedToValue(toOptions[selectedIndex].value);
    if (selectedToValue) {
      setError((privError) => ({
        ...privError,
        toError: "",
        noticeFundingError: "",
      }));
    }
    setTransferSubmissionError(false);
  };

  useEffect(() => {
    if (accountData && accountNumber) {
      const accData = accountData.find(
        (item) => item.accountNumber === accountNumber
      );
      if (!accData) return;
      const formattedBalance = formatBalance(accData.balance || 0);
      setSelectedData(accData);
      setDynamicDescriptionAccountName(
        getDynamicAccountDescription(accData?.accountName) ?? ""
      );
      setSelectedOptionFrom((prev) =>
        prev !== `${accData.accountName} - ${formattedBalance}`
          ? `${accData.accountName} - ${formattedBalance}`
          : prev
      );
      setSelectedFromValue(accData);
    }
  }, []);

  useEffect(() => {
    if (accountData) {
      const filteredAccData = accountData?.filter(
        ({ accountType, balance }) =>
          (accountType == AccountTypes.EASY_ACCESS ||
            accountType == AccountTypes.NOTICE_ACCOUNT) &&
          balance > 0
      );
      setFilteredAccData(filteredAccData);
      setSelectedFromData(filteredAccData[0]);
    }
    const token = localStorage.getItem("tokenVal") ?? "";
    setAuthToken(token);
  }, []);
  useEffect(() => {
    if (accountData) {
      const filteredAccData = accountData?.filter(
        ({ accountType, accountStatus }) =>
          accountType === AccountTypes.EASY_ACCESS ||
          accountType === AccountTypes.NOTICE_ACCOUNT ||
          (accountType === AccountTypes.FIXED_TERM_DEPOSIT &&
            accountStatus === "ACTIVE") ||
          accountStatus === "APPROVED"
      );
      const updatedAccounts = filteredAccData
        .map((account) => {
          const { accountType, creationDate } = account;
          if (accountType === AccountTypes.FIXED_TERM_DEPOSIT) {
            if (creationDate) {
              const creationDateObj = new Date(
                creationDate.split("-").reverse().join("-")
              );
              const depositRequireByDate = new Date(creationDateObj);
              depositRequireByDate.setDate(creationDateObj.getDate() + 13);
              const currentDateObj = new Date();
              if (
                currentDateObj <= depositRequireByDate &&
                currentDateObj >= creationDateObj
              ) {
                return account;
              }
            }
            return null;
          }
          return account;
        })
        .filter(Boolean);
      setFilteredAccDataTo(updatedAccounts as AccountData[]);
    }
  }, []);
  const fromOptions = useMemo(() => {
    return [
      ...filteredAccData.map((account) => ({
        label: `${account.accountName} - ${formatBalance(account.balance)}`,
        value: account,
      })),
    ];
  }, [filteredAccData]);
  const toOptions = useMemo(() => {
    return [
      {
        label: `${Strings.selectAccount} - ${CURRENCY_DEFAULT_VALUE}`,
        value: { balance: 0 },
      },
      ...filteredAccDataTo.map((account) => ({
        label: `${account.accountName} - ${formatBalance(account.balance)}`,
        value: account,
      })),
    ];
  }, [filteredAccDataTo]);
  const isSelectionError = useMemo(
    () => moveMoneyFlag && selectedToValue == toOptions[0],
    [selectedToValue, moveMoneyFlag]
  );
  const extractNumbers = (str: string) => {
    let numbers = str.match(/[-]?\d[\d,]*\.?\d*/g);
    return numbers ? numbers.map((num) => num.replace(/,/g, "")) : [];
  };
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (!value) {
        setTransferAmount("");
        return;
      }
      const numberPart = currencyToNumString(value);
      const decimalPointExists = numberPart.indexOf(".") !== -1;
      const sanitizedValue = decimalPointExists
        ? numberPart.replace(/[^0-9.]/g, "")
        : numberPart.replace(/[^0-9]/g, "");
      setTransferAmount(sanitizedValue);
      if (selectedToValue) {
        setError((privError) => ({
          ...privError,
          amountError: "",
          noticeFundingError: "",
        }));
      }
      setTransferSubmissionError(false);
    },
    []
  );
  const onMouseLeaveWithdrawalFormatting = useCallback(() => {
    if (!transferAmount) {
      setTransferAmount(CURRENCY_DEFAULT_VALUE);
    } else if (!transferAmount.includes(".")) {
      setTransferAmount(CURRENT_SYMBOL + transferAmount + ".00");
    } else {
      setTransferAmount(formatCurrencyWithDecimals(transferAmount));
    }
    setIsEditing(false);
  }, [transferAmount, formatCurrencyWithDecimals]);
  const onMouseEnterWithdrawalFormatting = useCallback(() => {
    setIsEditing(true);
  }, []);

  const validateForm = () => {
    const newErrors: MoveMoneyError = {};

    const sanitizedTransferAmount = Number(
      transferAmount.replace(/[^\d.]/g, "")
    );

    if (!selectedFromValue) {
      newErrors.fromError = Strings.moveMoneyFromError;
    }
    if (
      !selectedToValue ||
      Object.keys(selectedToValue).length === 1 ||
      Object.keys(selectedToValue).length === 0
    ) {
      newErrors.toError = Strings.moveMoneyToError;
    }

    if (
      selectedFromValue &&
      selectedToValue &&
      selectedFromValue.accountNumber === selectedToValue.accountNumber
    ) {
      newErrors.toError = Strings.moveMoneySameAccountError;
    }
    if (!sanitizedTransferAmount || sanitizedTransferAmount <= 0) {
      newErrors.amountError = Strings.moveMoneyEmptyAmountError;
    }
    if (
      selectedFromValue &&
      sanitizedTransferAmount > selectedFromValue?.balance
    ) {
      newErrors.amountError = `${
        Strings.moveMoneyValidAmountError
      } ${CURRENT_SYMBOL}${selectedFromValue?.balance.toFixed(2)}.`;
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateTransfer = (
    selectedFromValue: SelectedData,
    selectedToValue: SelectedData
  ) => {
    if (
      selectedFromValue.accountType === AccountTypes.NOTICE_ACCOUNT &&
      selectedToValue.accountType === AccountTypes.FIXED_TERM_DEPOSIT
    ) {
      if (
        isFundingPeriodValid &&
        selectedFromValue.noticePeriod <= fundingPeriod
      ) {
        return true; // valid transfer
      } else {
        setError((privError) => ({
          ...privError,
          noticeFundingError: Strings.moveMoneyNoticeError,
        }));
        setTransferSubmissionError(true);
        return false; // Invalid Error
      }
    }
  };

  const makeTransfer = async (
    selectedFromValue: SelectedData,
    selectedToValue: SelectedData,
    transferAmount: number
  ) => {
    setIsLoading(true);
    try {
      let postData: ApiRequest = {
        scheme: "FPS",
        isFeesApplicable: false,
        amount: transferAmount,
        clientId: "",
        fromAccount: {
          accountNumber: selectedFromValue.accountNumber,
          accountName: selectedFromValue.accountOwner,
        },
        toAccount: {
          accountNumber: selectedToValue.accountNumber,
          accountName: selectedToValue.accountOwner,
        },
        reference: generateRandomAlphanumeric(16), // this is required
      };

      let postDataNtToFt = {
        withdrawalNoticeDetails: {
          amount: transferAmount,
          fromAccountNumber: selectedFromValue.accountNumber,
          toAccountNumber: selectedToValue.accountNumber,
          withdrawToAccount: AccountTypes.EASY_ACCESS,
          channel: Strings.channelMobile,
        },
      };
      let transferResult;

      if (
        selectedFromValue?.accountType === AccountTypes.NOTICE_ACCOUNT &&
        (selectedToValue?.accountType === AccountTypes.FIXED_TERM_DEPOSIT ||
          selectedToValue?.accountType === AccountTypes.NOTICE_ACCOUNT ||
          selectedToValue?.accountType === AccountTypes.EASY_ACCESS)
      ) {
        transferResult = await transferFromNtToOthers(
          WITHDRAWAL_AMOUNT_NOTICE_ACCOUNT,
          postDataNtToFt,
          authToken
        );
      } else {
        transferResult = await withdrawalEasyAccessAccount(
          WITHDRAWAL_AMOUNT_EASY_ACCESS_ACCOUNT,
          postData,
          authToken
        );
      }

      if (transferResult && typeof transferResult === "object") {
        navigate(`/confirmTransfer`, {
          state: {
            amount: transferAmount,
            from: selectedFromValue,
            to: selectedToValue,
            accountNumber: accountNumber,
          },
        });
      } else {
        setTransferSubmissionError(true);
        setLoadModalFlag(false);
      }

      return transferResult;
    } catch (err) {
      setTransferSubmissionError(true);
      setLoadModalFlag(false);
    } finally {
      setIsLoading(false);
    }
  };
  const confirmTransferHandler = async () => {
    setMoveMoneyFlag(true);

    if (validateForm()) {
      const sanitizedTransferAmount = Number(
        transferAmount.replace(/[^\d.]/g, "")
      );
      const isValidTranferToFT = validateTransfer(
        selectedFromValue,
        selectedToValue
      );

      if (!isValidTranferToFT) {
        if (
          selectedFromValue?.accountType === AccountTypes.NOTICE_ACCOUNT &&
          selectedToValue?.accountType === AccountTypes.FIXED_TERM_DEPOSIT
        ) {
          setTransferSubmissionError(false);
          return;
        }
      } else {
        setTransferSubmissionError(false);
      }

      try {
        makeTransfer(
          selectedFromValue,
          selectedToValue,
          sanitizedTransferAmount
        );
      } catch (err) {
        setTransferSubmissionError(true);
        setLoadModalFlag(false);
      }
    }
  };

  return (
    <>
      <LoadingModal show={loadModalFlag} size="sm" />
      <main className="mt-4 container">
        <section className={`${styles.withdrawContainer} `}>
          <header>
            <Breadcrumbs
              data={selectedData}
              previousPath={`/moveMoney/${selectedData?.accountNumber}`}
            />
            <h1 className={`${headingStyles.headingMedium} mt-2`}>
              {Strings.moveMoneyHeading}
            </h1>
          </header>
          <section>
            <div>
              From
              <span className="text-danger"> *</span>
            </div>
            <SelectInput
              labelTitle={false && "Select account"}
              id="userNameTitle"
              required
              className="rounded-0"
              onChange={selectFromInputHandler}
              selected={selectedOptionFrom || ""}
              options={fromOptions.map((option: any) => option.label)}
              isError={isSelectionError}
              errorMsg={Strings.selectAccountErrorText}
              aria-label="Select from account"
            />
            {error?.fromError && (
              <p>
                <em className="text-danger">{error?.fromError}</em>
              </p>
            )}
            <p>
              {selectedFromValue?.accountName &&
                selectedFromValue?.accountName.includes("Notice") &&
                Strings.moveMoneyNoticeWarningFrom}
            </p>
          </section>
          <section>
            <div>
              To
              <span className="text-danger"> *</span>
            </div>
            <SelectInput
              labelTitle={false && "Select account"}
              id="userNameTitle"
              required
              className={`rounded-0 ${error?.toError && "input-error-border"}`}
              onChange={selectToInputHandler}
              selected={""}
              options={toOptions?.map((option: any) => option.label)}
              isError={isSelectionError}
              errorMsg={Strings.selectAccountErrorText}
              aria-label="Select from account"
            />
            {error?.toError && (
              <div>
                <em className="text-danger">{error.toError}</em>
              </div>
            )}
            <p>
              {selectedToValue?.accountName &&
                selectedToValue?.accountName.includes("Notice") &&
                Strings.moveMoneyNoticeWarningTo}
            </p>
          </section>
          <section>
            <div>
              {Strings.transferAmount}
              <span className="text-danger"> *</span>
            </div>
            <input
              id="transferAmount"
              type={Strings.defaultInputType}
              className={`form-control ${
                error?.amountError && "input-error-border"
              }`}
              placeholder={Strings.transferAmountPlaceholder}
              value={
                isEditing
                  ? transferAmount
                  : transferAmount || CURRENCY_DEFAULT_VALUE
              }
              onChange={handleInputChange}
              onMouseLeave={onMouseLeaveWithdrawalFormatting}
              onMouseEnter={onMouseEnterWithdrawalFormatting}
              aria-label="Enter transfer amount"
            />
            {error?.amountError && (
              <div>
                <em className="text-danger">{error?.amountError}</em>
              </div>
            )}
          </section>
          {error?.noticeFundingError && (
            <p className={`${styles.withdrawSubHeading} text-danger`}>
              {Strings.moveMoneyNoticeError}
            </p>
          )}
          {transferSubmissionError && (
            <div className={`${styles.withdrawSubHeading} text-danger`}>
              {Strings.withdrawalSubmissionError}{" "}
              <Link to="/contact-us" className="text-danger">
                <u>{Strings.contactUsStr}</u>
              </Link>
              .
            </div>
          )}
          <footer className={styles.continueButtonStyle}>
            <button
              type="button"
              className="btn btn-secondary btn-md"
              onClick={() => navigate(-1)}
              aria-label="Cancel withdrawal"
            >
              {Strings.btnCancel}
            </button>
            <button
              type="button"
              className={"btn btn-md btn-primary_one"}
              aria-label="Confirm withdrawal"
              onClick={confirmTransferHandler}
            >
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Img
                    src={MonuUnion}
                    loading="lazy"
                    height="30"
                    width="30"
                    alt="User Profile Picture"
                  />
                  <span className="ps-1">
                    {Strings.transferLoadingButtonText}
                  </span>
                </div>
              ) : (
                Strings.confirmTransfer
              )}
            </button>
          </footer>
        </section>
      </main>
    </>
  );
};
export default MoveMoneyComponent;
