import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImgPath from "../../common/constant/imgConstanct";
import { getProductCategoryData } from "../../services/appApi";
import Strings from "../../theme/string";
import { AccountTypes } from "../../enums/customerService.enums";

export const SavingScreen = () => {
  const navigate = useNavigate();
  const [productCategory, setProductCategory] = useState<any>();

  useEffect(() => {
    apiHandler();
  }, []);

  const apiHandler = async () => {
    let apiData = getProductCategoryData();
    if (apiData) {
      setProductCategory(apiData);
    } else {
      console.error("Error fetching summary box data!");
    }
  };
  return (
    <div className="container savings-selectaccount-container">
      <div className="row">
        <div>
          <h3>{Strings.selectCategoryTitle}</h3>
        </div>
        <div>
          <span>{Strings.selectCategoryDescription}</span>
        </div>
      </div>
      <div className="row category-cards-row">
        {productCategory?.data.map((pData: any) => (
          <div className="col" key={pData?.id}>
            <div className="account-item-parent">
              <div className="category-details-content">
                <div className="image-container">
                  <img
                    src={require(`../../assest/${pData?.src}`)}
                    className="account-type-image"
                    alt={`${pData?.title} icon`}
                  />
                </div>
                <h4 className="category-title">{pData?.title}</h4>
                <div className="product-pointers-container">
                  {pData?.description.map((dText: string, key: number) => (
                    <div key={key} className="pointer-point">
                      <span>
                        <ImgPath.DiamondTickIcon />
                      </span>
                      <span>{dText}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => navigate(`/onBoardingStart/${pData?.title}`)}
                  className={`btn btn-primary_one btn-lg border-radius-0 font-size-16px width-100-percent`}
                >
                  {pData?.btnName}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
