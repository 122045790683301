import React, { useEffect, useState } from "react";
import styles from "../../styleModules/memorableWord.module.scss";
import headingStyles from "../../styleModules/commonHeading.module.scss";
import Strings from "../../theme/string";
import { Img } from "../../common/basic components/img";
import tickIcon from "../../assest/tickIcon.svg";
import { Button } from "../../common/basic components/button";
import fscsIcon from "../../assest/fscsIcon.svg";
import { MemorableSectionState } from "../../interfaces/memorableSection.interface";
import { MINIMUM_SEVEN_CHAR_REGEX } from "../../common/constant/regexConstants";
import { useNavigate } from "react-router-dom";
import {
  setMemorableWordAndHint,
  addMemorableUrl,
} from "../../redux/reducer/memorableWordSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import {
  decryptMessage,
  encryptMessage,
  generateKey,
} from "../../common/utils/cryptoEncryptionDecryption";
import { setUserName } from "../../redux/reducer/navigationBarSlice";
import { useLocation } from "react-router-dom";
import MarketingPreferences from "../../common/components/MarketingPreferences";
import { createMemorableWord } from "./createMemorableWord";
import { GET_PARTY_DETAILS, POST_MEMORABLE_WORD } from "../../services/url";
import { generateRandomAlphanumeric } from "../../common/utils/randomUniqueAlphanumStringGenerator";
import { updateMarketingPreferences } from "../../services/updateMarketingPreferences";
import { MarketingPreferencesInterface } from "../../interfaces/marketingPreferences.interface";

const MemorableWord = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const privUrl = useLocation();
  const [authToken, setAuthToken] = useState("");

  const { memorableWord, hint, key } = useAppSelector(
    (state: any) => state.memorableWord
  );

  const getMarketingPreferences: MarketingPreferencesInterface = useAppSelector(
    (state: any) => state.marketingPrefences
  );

  const [state, setState] = useState<MemorableSectionState>({
    memorableWord: "",
    hint: hint ?? "",
    memorableWordErrorFlag: false,
  });

  const decryptHandler = async () => {
    setState({
      ...state,
      memorableWord:
        memorableWord && (await decryptMessage(memorableWord, key)),
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("tokenVal") ?? "";
    setAuthToken(token);
  }, []);

  useEffect(() => {
    decryptHandler();
  }, []);

  // Memoize input change handler

  const InputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValidMemorableWord =
      name === "memorableWord" && MINIMUM_SEVEN_CHAR_REGEX.test(value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      memorableWordErrorFlag:
        name === "memorableWord" && state.memorableWordErrorFlag
          ? !isValidMemorableWord
          : prevState.memorableWordErrorFlag,
    }));
  };

  const handleContinue = () => {
    const isValid = MINIMUM_SEVEN_CHAR_REGEX.test(state.memorableWord);
    if (!state.memorableWordErrorFlag && isValid) {
      (async () => {
        const key = await generateKey();
        const encrypteMmemorableWord = await encryptMessage(
          state.memorableWord,
          key
        );
        const encryptedHint = await encryptMessage(state.hint, key);
        dispatch(
          setMemorableWordAndHint({
            memorableWord: await encryptMessage(state.memorableWord, key),
            hint: state.hint,
            key: key,
          })
        );
        dispatch(setUserName("Elliot Smith"));
        dispatch(addMemorableUrl(privUrl));

        const updatedPreferences = {
          smsOptIn: getMarketingPreferences.smsOptIn,
          emailOptIn: getMarketingPreferences.emailOptIn,
          postOptIn: getMarketingPreferences.postOptIn,
        };

        dispatch<any>(updateMarketingPreferences({ updatedPreferences }));

        const postData = {
          memorableWord: encrypteMmemorableWord,
          initialisationVector: generateRandomAlphanumeric(16),
          hint: encryptedHint,
        };

        try {
          let response = await createMemorableWord(
            POST_MEMORABLE_WORD,
            postData,
            authToken
          );

          if (response && typeof response === "object") {
            navigate("/dashboard");
          }
        } catch (error) {
          setState((prevState) => ({
            ...prevState,
            memorableWordErrorFlag: !isValid,
          }));
        }
      })();
    } else {
      setState((prevState) => ({
        ...prevState,
        memorableWordErrorFlag: !isValid,
      }));
    }
  };

  return (
    <section
      className={styles.memorableContainer}
      aria-labelledby="memorable-word-section"
    >
      <form>
        <div>
          <article className={styles.container}>
            <div className="d-flex my-5 mx-4 align-items-center gap-5">
              <section className="d-flex flex-column gap-3">
                <div className={styles.memorableWordHeader}>
                  {Strings?.memorableSectionHeading}
                </div>
                <div className={headingStyles.subHeading}>
                  {Strings?.memorableSectionSubHeading}
                </div>
                <div className={styles.memorableInputStyle}>
                  <input
                    id="memorableWord"
                    type={Strings?.defaultInputType}
                    className={`form-control ${
                      state.memorableWordErrorFlag && styles.errorText
                    }`}
                    placeholder={Strings?.memorableWordPlaceholder}
                    onChange={InputChangeHandler}
                    name="memorableWord"
                    value={state.memorableWord}
                    // aria-invalid={state.memorableWordErrorFlag}
                    aria-describedby="memorableWordHelp"
                  />
                  {!state.memorableWordErrorFlag && (
                    <div id="memorableWordHelp" className="d-flex gap-3">
                      <span>
                        <Img
                          src={tickIcon}
                          className={`img-fluid ${styles.imageStyle}`}
                          alt="Tick icon"
                          width="24px"
                          height="24px"
                          aria-label="Tick icon"
                        />
                      </span>
                      <span>{Strings?.memorableWordHelperText}</span>
                    </div>
                  )}
                  {state.memorableWordErrorFlag && (
                    <span id="memorableWordError" className={styles.errorText}>
                      {Strings?.memorableWordErrorMessage}
                    </span>
                  )}
                </div>
                <label htmlFor="hint" className="d-flex flex-column gap-3">
                  <span className={styles.subHeading}>
                    {Strings?.memorableSectionHintText}
                  </span>
                  <input
                    id="hint"
                    type={Strings.defaultInputType}
                    className="form-control"
                    placeholder={Strings?.memorableSectionHintPlaceholder}
                    onChange={InputChangeHandler}
                    name="hint"
                    value={state.hint}
                  />
                </label>
                <div className="d-flex justify-content-center">
                  <Img
                    src={fscsIcon}
                    className={`img-fluid ${styles.imageStyle}`}
                    alt="FSCS icon"
                    width="83px"
                    height="83px"
                    aria-label="FSCS icon"
                    onClick={() => navigate("/dashboard")}
                  />
                </div>
              </section>
            </div>
          </article>
          <article className={`mt-4 ${styles.container}`}>
            <section className="p-4">
              <MarketingPreferences apiOnToggle={false} />
            </section>
          </article>
          <div className={styles.buttonContainer}>
            <Button
              label={Strings?.btnContinue}
              className={"btn btn-md btn-primary_one"}
              onClick={handleContinue}
              aria-disabled={state.memorableWordErrorFlag}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
export default MemorableWord;
