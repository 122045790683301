import { useMemo, useState } from "react";
import React from "react";
import Strings from "../../../theme/string";
import {
  AddressFieldProps,
  StepperUserDetails,
} from "../../../interfaces/reviewStepper.interface";
import { ValidationInput } from "../validationInput";
import { SuggestionInput } from "../../../common/components/suggestionInput";
import { PART_OF_ADDRESS } from "../../../common/constant/customConstant";
import { AddressFieldEnum } from "../../../enums/reviewStepper.enums";
import styles from "../../../styleModules/commonHeading.module.scss";
const AddressField: React.FC<AddressFieldProps> = ({
  stepperDetails,
  reviewFlag = false,
}) => {
  const [spanVal, setSpanVal] = useState<string>(Strings?.addItManually);
  const { stepperUserDetails } = stepperDetails;
  const {
    addressline1,
    addressline2,
    addressline3,
    addressline4,
    posttown,
    postcode,
  } = stepperUserDetails;
  const renderInput = (
    id: string,
    label: string,
    placeholder: string,
    value: StepperUserDetails,
    errorMsg?: string,
    blankErrorMsg?: string,
    regex?: string,
    isRequired: boolean = false,
    customBlankErrorClass?: string
  ) => (
    <ValidationInput
      type={Strings.defaultInputType}
      lableValue={label}
      placeHolder={placeholder}
      value={reviewFlag ? value.draftValue : value?.value}
      mainValue={value?.value}
      mainValueValidation={value?.isValidate}
      id={id}
      regExp={regex}
      isError={
        stepperDetails.buttonValidatonIsRequired &&
        !(reviewFlag ? value.isdraftValidate : value?.isValidate)
      }
      spanclassName={"fst-italic font-size-12px"}
      errorMsg={errorMsg}
      blankErrorMsg={blankErrorMsg}
      disabled={spanVal === Strings.addItManually}
      labelDangerRequired={isRequired}
      reviewFlag={reviewFlag}
      customBlankErrorClass={customBlankErrorClass}
    />
  );

  const getDynamicAddressField = (key: keyof typeof Strings): string =>
    String(Strings[key]);

  const addressFields = useMemo(
    () => (
      <>
        <div className="row mb-3">
          <SuggestionInput
            lableValue={Strings.postalAddress}
            labelDangerRequired={true}
            placeHolder={Strings.postalAddressPlaceholder}
            isBottomText={true}
            bottomText={Strings.postalAddressBottomText}
            type={Strings.defaultInputType}
            errorMsg={Strings.postalAddressErrorMsg}
            id={AddressFieldEnum.ADDRESS_ONE}
            value={reviewFlag ? addressline1.draftValue : addressline1?.value}
            spanclassName="font-size-16px"
            isError={
              stepperDetails.buttonValidatonIsRequired &&
              !(reviewFlag
                ? addressline1.isdraftValidate
                : addressline1?.isValidate)
            }
            reviewFlag={reviewFlag}
            blankErrorMsg={Strings.postCodeBlankErrorText}
            mainValue={{
              addressline1: addressline1?.value,
              addressline2: addressline2?.value,
              addressline3: addressline3?.value,
              addressline4: addressline4?.value,
              posttown: posttown?.value,
              postcode: postcode?.value,
            }}
            mainValueValidation={{
              addressline1: addressline1?.isValidate,
              addressline2: addressline2?.isValidate,
              addressline3: addressline3?.isValidate,
              addressline4: addressline4?.isValidate,
              posttown: posttown?.isValidate,
              postcode: postcode?.isValidate,
            }}
            {...{ spanVal, setSpanVal }}
          />
        </div>
        {PART_OF_ADDRESS.map((line: string, idx: number) => (
          <div key={line} className="row mb-3">
            {renderInput(
              line,
              getDynamicAddressField(
                `addressLine${idx + 2}` as keyof typeof Strings
              ),
              getDynamicAddressField(
                `addressLine${idx + 2}Placeholder` as keyof typeof Strings
              ),
              stepperUserDetails[line as keyof typeof stepperUserDetails]
            )}
          </div>
        ))}
        <div className="row mb-3">
          {renderInput(
            AddressFieldEnum.POST_TOWN,
            Strings.townCity,
            Strings.townCityPlaceholder,
            posttown,
            Strings.defaultNameErrorMsg,
            Strings.townCityBlankErrorMsg,
            Strings.nameRegex,
            true,
            `${styles.errorHeadingSmallStyle} mt-1`
          )}
        </div>
        <div className="row mb-3">
          {renderInput(
            AddressFieldEnum.POST_CODE,
            Strings.postalCode,
            Strings.postalCodePlaceholder,
            postcode,
            Strings.postalCodeErrorMsg,
            Strings.postalCodeBlankErrorMsg,
            Strings.postalCodeRegex,
            true,
            `${styles.errorHeadingSmallStyle} mt-1`
          )}
        </div>
      </>
    ),
    [stepperDetails, reviewFlag, spanVal]
  );
  return <div>{addressFields}</div>;
};
export default AddressField;
