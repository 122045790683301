import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { INITIAL_COUNT } from "../../common/constant/customConstant";
import {
  numberFormat,
  numberFormatWithDecimal,
  sortCodeAutoFormat,
} from "../../common/modules/validation";
import { updatedLinkUKBank } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { getLinkBankAccountData } from "../../services/appApi";
import styles from "../../styleModules/reviewStepper.module.scss";
import Strings from "../../theme/string";
import { HelperLinkBankAccount } from "./helperLinkBankAccount";
import { HelperRadioButtonLinkBankAccount } from "./helperRadioButtonLinkAccount";
import commonHeadingStyles from "../../styleModules/commonHeading.module.scss";
import micsStyles from "../../styleModules/commonMicsStyles.module.scss";

interface LinkBankAccountData {
  data: {
    description: string;
  };
}

const StepperLinkBankAccount = ({
  editFlag = false,
  isEditButtonRequired = true,
  allOptionRequired = true,
  isHeadingRequired = true,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const stepperDetails: any = useAppSelector(
    (state: any) => state.stepperDetails
  );
  const mainObj = stepperDetails?.stepperLinkUKBank;
  const [linkBankData, setLinkBankData] = useState<LinkBankAccountData>();

  useEffect(() => {
    if (!mainObj?.interestBeingPaid?.value)
      dispatching("interestBeingPaid", "", false, false);
    apiHandler();
  }, []);

  const dispatching = (
    key: string,
    tmpVal: string,
    isValid: boolean,
    saved: boolean
  ) => {
    const draftValue = editFlag ? { draftValue: tmpVal } : {};
    const value = {
      value: editFlag
        ? saved
          ? mainObj?.[key].draftValue
          : mainObj?.[key]?.value
        : tmpVal,
    };
    dispatch<any>(
      updatedLinkUKBank({
        fieldName: key,
        ...value,
        ...draftValue,
        isValidate: isValid,
      })
    );
  };

  const apiHandler = async () => {
    let apiData = getLinkBankAccountData();
    if (apiData) {
      setLinkBankData(apiData);
    } else {
      console.error("Error fetching link bank account data!");
    }
  };

  const handleSortCodeInput = (
    valueParam: string,
    key: string,
    saved: boolean
  ) => {
    let targetSortCodeValue: any = sortCodeAutoFormat(valueParam);
    let tempFormat = valueParam.replace(/-/g, "");
    dispatching(
      key,
      targetSortCodeValue,
      tempFormat.length == Strings.six ? true : false,
      saved
    );
  };

  const handleAccountNumberInput = (
    valueParam: string,
    key: string,
    saved: boolean
  ) => {
    let targetAccountValue: any = numberFormat(valueParam);
    dispatching(
      key,
      targetAccountValue,
      targetAccountValue.length == Strings.eight ? true : false,
      saved
    );
  };

  const handleDepositAmountInput = (
    valueParam: any,
    key: string,
    saved: boolean
  ) => {
    let targetDepositAmountValue: any = numberFormatWithDecimal(valueParam);
    dispatching(
      key,
      targetDepositAmountValue,
      targetDepositAmountValue.length > INITIAL_COUNT ? true : false,
      saved
    );
  };

  const checkingSortCodeHavingError = (param: string, lengthCount: number) => {
    if (
      editFlag &&
      mainObj?.[param]?.draftValue?.replace(/-/g, "").length != lengthCount
    ) {
      return true;
    } else if (
      mainObj?.[param]?.value?.replace(/-/g, "").length != lengthCount
    ) {
      return true;
    } else return false;
  };

  const updateHandler = (param: string, tmpVal: string, saved = false) => {
    switch (param) {
      case Strings.accountNumber:
        handleAccountNumberInput(tmpVal, Strings.accountNumber, saved);
        break;
      case Strings.sortCode:
        handleSortCodeInput(tmpVal, Strings.sortCode, saved);
        break;
      case Strings.depositAmount:
        handleDepositAmountInput(tmpVal, Strings.depositAmount, saved);
        break;
    }
  };

  const updateInterestBeingPaidHandler = () => {
    dispatching(
      Strings.interestBeingPaid,
      mainObj?.interestBeingPaid?.draftValue,
      true,
      true
    );
  };

  return (
    <>
      <div className="linkBankSection">
        {!editFlag && (
          <div className="row">
            <p className="new-line lh-base">
              {linkBankData?.data?.description}
            </p>
          </div>
        )}
        {isHeadingRequired && editFlag && (
          <div className="d-flex align-items-center gap-3 mb-3 mt-4">
            <div className="col-md-8 col-lg-6">
              <div
                className={`${commonHeadingStyles.commonBoldHighlightedHeading} mb-2`}
              >
                {Strings?.interestRadioLinkedAccount}
              </div>
              <div className={micsStyles.customLine}></div>
            </div>
          </div>
        )}

        <div className="gap-4 mb-3">
          <HelperLinkBankAccount
            {...{ mainObj, handleSortCodeInput, stepperDetails }}
            label={Strings.linkBankSortCodeLabel}
            placeholder={Strings.linkBankSortCodePlaceholder}
            updateInput={updateHandler}
            helperText={Strings.linkBankSortCodeHelperText}
            maxLength={Strings.eight}
            isError={checkingSortCodeHavingError(Strings.sortCode, Strings.six)}
            value={mainObj?.["sortCode"]?.value}
            errorMessage={
              mainObj?.sortCode?.value?.replace(/-/g, "").length ==
              INITIAL_COUNT
                ? Strings.linkBankSortCodeBlankError
                : Strings.linkBankSortCodeError
            }
            fieldName={Strings.sortCode}
            editFlag={editFlag}
            isEditButtonRequired={isEditButtonRequired}
          />
        </div>

        <div className="gap-4 mb-3">
          <HelperLinkBankAccount
            {...{ mainObj, handleSortCodeInput, stepperDetails }}
            label={Strings.linkBankAccountNumberLabel}
            placeholder={Strings.linkBankAccountNumberPlaceholder}
            updateInput={updateHandler}
            helperText={Strings.linkBankAccountNumberHelperText}
            maxLength={Strings.eight}
            isError={checkingSortCodeHavingError(
              Strings.accountNumber,
              Strings.eight
            )}
            value={mainObj?.["accountNumber"]?.value}
            errorMessage={
              mainObj?.accountNumber?.value?.length == INITIAL_COUNT
                ? Strings.linkBankAccountNumberBlankError
                : Strings.linkBankAccountNumberError
            }
            fieldName={Strings.accountNumber}
            editFlag={editFlag}
            isEditButtonRequired={isEditButtonRequired}
          />
        </div>

        {allOptionRequired && (
          <div className="gap-4 mb-3">
            <HelperRadioButtonLinkBankAccount
              {...{ mainObj, stepperDetails }}
              dispatching={(item: string) =>
                dispatching("interestBeingPaid", item, true, false)
              }
              id={id}
              editFlag={editFlag}
              fieldName={Strings.interestBeingPaid}
              label={Strings.linkBankInterestRadioLabel}
              className=" col-md-8 col-lg-6"
              updateInput={updateInterestBeingPaidHandler}
            />
          </div>
        )}

        {allOptionRequired && (
          <div className="gap-4 mb-3">
            <HelperLinkBankAccount
              {...{ mainObj, handleSortCodeInput, stepperDetails }}
              label={Strings.linkBankAmountDepositLabel}
              placeholder={Strings.linkBankAmountDepositPlaceholder}
              updateInput={updateHandler}
              helperText={`${Strings.example} ${
                mainObj?.depositAmount?.value ?? ""
              } ${Strings.linkBankAmountDepositHelperText}`}
              isError={!mainObj?.depositAmount?.isValidate ? true : false}
              value={mainObj?.[Strings.depositAmount]?.value}
              errorMessage={Strings.linkBankAmountDepositError}
              fieldName={Strings.depositAmount}
              editFlag={editFlag}
            />
          </div>
        )}
      </div>
      <span></span>
    </>
  );
};

export default StepperLinkBankAccount;
