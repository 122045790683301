import axios, { AxiosError } from "axios";
import { PartyDetails } from "../interfaces/footer.interface";
import { SaveApplicationPostData } from "../interfaces/verifyCustomerDetails.interface";

export const fetchTSToken = async (url: string) => {
  const data =
    "grant_type=client_credentials&client_id=5r0so62o4p7i0pusq3tl06vd70&client_secret=91260ljr2rb4kgi0f1vdgkdhod8ceueaa9ifafl2ta34i47viif";
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};

export const createPartyAPI = async (
  url: string,
  token: string,
  postData: { partyDetails: PartyDetails }
) => {
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};

export const savingApplicationApi = async (
  url: string,
  token: string,
  postData: SaveApplicationPostData
) => {
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    const typedError = error as AxiosError;
    if (axios.isAxiosError(typedError)) {
      if (typedError?.response) {
        return typedError?.message || "An error occurred";
      } else if (typedError?.request) {
        return "No response received from server";
      }
    }
    return typedError?.message;
  }
};
