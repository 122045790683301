import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/redux-hooks";
import Strings from "../../../../theme/string";
import GenericViewFormComponent from "./GenericViewFormComponent";
import styles from "../../../../styleModules/reviewStepper.module.scss";
import DesignationField from "../../../personalInformation/personalInformationFields/designationField";
import {
  updateUserDetails,
  updateValidationIsRequired,
} from "../../../../redux/reducer/stepperSubProductSlice";
import DobField from "../../../personalInformation/personalInformationFields/dobField";
import NameField from "../../../personalInformation/personalInformationFields/nameField";
import {
  EditFlags,
  RootState,
} from "../../../../interfaces/reviewStepper.interface";
import { SaveButtonComponent } from "./miscComponents/saveButtonComponent";
import { EditButtonComponent } from "./miscComponents/editButtonComponent";
import {
  ActionTypeEnum,
  AddressFieldEnum,
  ReviewFieldEnum,
  ReviewNameFieldEnum,
} from "../../../../enums/reviewStepper.enums";
import AddressField from "../../../personalInformation/personalInformationFields/addressField";
import { PART_OF_ADDRESS } from "../../../../common/constant/customConstant";
import commonHeadingStyles from "../../../../styleModules/commonHeading.module.scss";
import micsStyles from "../../../../styleModules/commonMicsStyles.module.scss";

type ActionType =
  | { type: ActionTypeEnum.SET_EDIT_MODE; payload: keyof EditFlags }
  | { type: ActionTypeEnum.RESET_EDIT_MODE; payload: keyof EditFlags };

const initialEditFlags: EditFlags = {
  title: false,
  name: false,
  dateOfBirth: false,
  address: false,
};

// FOR USE-REDUCER HOOK USED IN THIS COMPONENT
const editFlagsReducer = (state: EditFlags, action: ActionType): EditFlags => {
  switch (action.type) {
    case ActionTypeEnum.SET_EDIT_MODE:
      return { ...state, [action.payload]: true };
    case ActionTypeEnum.RESET_EDIT_MODE:
      return { ...state, [action.payload]: false };
    default:
      return state;
  }
};

const fieldComponents = {
  title: DesignationField,
  name: NameField,
  dateOfBirth: DobField,
  address: AddressField,
} as const;

type FieldComponentMapping = keyof typeof fieldComponents;

const PersonalDetailsReviewComponent = ({
  isAddressRequired = true,
  isEditBtnRequired = true,
  isInformationTitleRequired = true,
}) => {
  const stepperDetails = useAppSelector(
    (state: RootState) => state.stepperDetails
  );
  const dataUserDetails = stepperDetails.stepperUserDetails;

  const {
    emailId,
    mobileNumber,
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirthValue,
    addressline1,
    addressline2,
    addressline3,
    addressline4,
    posttown,
    postcode,
  } = useAppSelector((state: RootState) => ({
    emailId: dataUserDetails?.email?.value,
    mobileNumber: dataUserDetails?.mobileWithoutCode?.value,
    title: dataUserDetails?.title?.value,
    firstName: dataUserDetails?.firstName?.value,
    middleName: dataUserDetails?.middleName?.value,
    lastName: dataUserDetails?.lastName?.value,
    dateOfBirthValue: dataUserDetails?.dateOfBirth?.value,
    addressline1: dataUserDetails?.addressline1?.value,
    addressline2: dataUserDetails?.addressline2?.value,
    addressline3: dataUserDetails?.addressline3?.value,
    addressline4: dataUserDetails?.addressline4?.value,
    posttown: dataUserDetails?.posttown?.value,
    postcode: dataUserDetails?.postcode?.value,
  }));

  const [editFlags, dispatch] = useReducer(editFlagsReducer, initialEditFlags);
  const appDispatch = useAppDispatch();

  const editModeHandler = useCallback(
    (field: keyof EditFlags) => {
      dispatch({ type: ActionTypeEnum.SET_EDIT_MODE, payload: field });
    },
    [dispatch]
  );

  const saveModeHandler = useCallback(
    (field: keyof EditFlags, titles: string[]) => {
      if (
        titles.every(
          (title) =>
            (dataUserDetails[title]?.isdraftValidate ||
              PART_OF_ADDRESS.includes(title)) ??
            true
        )
      ) {
        titles.forEach((title) => {
          appDispatch(
            updateUserDetails({
              fieldName: title,
              value: dataUserDetails[title]?.draftValue ?? "",
              draftValue: dataUserDetails[title]?.draftValue ?? "",
              isValidate: dataUserDetails[title]?.isValidate ?? true,
              isdraftValidate: dataUserDetails[title]?.isdraftValidate ?? true,
            })
          );
        });
        dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE, payload: field });
      } else {
        appDispatch(updateValidationIsRequired(true));
      }
    },
    [appDispatch, dataUserDetails]
  );

  const personalDetailsReadData = useMemo(
    () => [
      {
        title: `${Strings.reviewDetailsEmailAddress}`,
        fieldData: `${emailId}`,
      },
      {
        title: `${Strings.reviewDetailsPhoneNumber}`,
        fieldData: `${mobileNumber}`,
      },
    ],
    [emailId, mobileNumber, dataUserDetails]
  );

  const viewAddressData = useMemo(
    () =>
      [
        addressline1,
        addressline2,
        addressline3,
        addressline4,
        posttown,
        postcode,
      ]
        .filter((address) => Boolean(address))
        .join(", "),
    [addressline1, addressline2, addressline3, addressline4, posttown, postcode]
  );

  const renderField = useCallback(
    (
      label: string,
      editFlag: boolean,
      value: string,
      field: keyof EditFlags,
      fieldType: FieldComponentMapping,
      titles: string[]
    ) => {
      const FieldComponent = fieldComponents[fieldType];
      return (
        <div key={field} className="col-md-8 col-lg-6">
          <div className="d-flex align-items-center form-label gap-3">
            <div>
              <label htmlFor={`formField-${field}`}>
                {label}
                <span className="text-danger"> *</span>
              </label>
            </div>
            {editFlag && (
              <SaveButtonComponent
                onClick={() => saveModeHandler(field, titles)}
              />
            )}
          </div>
          {!editFlag && (
            <div className="d-flex">
              <div
                id={`formField-${field}`}
                className={styles.genericFormFieldStyle}
                aria-label={`${field} field`}
              >
                <div className={styles.genericFormFieldTextStyle}>{value}</div>
              </div>
              {isEditBtnRequired && (
                <EditButtonComponent onClick={() => editModeHandler(field)} />
              )}
            </div>
          )}

          {editFlag && (
            <div className="row gap-4 mt-4">
              {fieldType == ReviewFieldEnum.ADDRESS ? (
                <FieldComponent
                  stepperDetails={stepperDetails}
                  fieldName={Strings.address as ReviewNameFieldEnum}
                  reviewFlag
                />
              ) : (
                titles.map((name) => (
                  <FieldComponent
                    key={name}
                    stepperDetails={stepperDetails}
                    fieldName={name as ReviewNameFieldEnum}
                    reviewFlag
                  />
                ))
              )}
            </div>
          )}
        </div>
      );
    },
    [editModeHandler, saveModeHandler, stepperDetails, dataUserDetails]
  );

  return (
    <div>
      <div>
        {isInformationTitleRequired && (
          <div className="d-flex align-items-center gap-3 mb-3 mt-4">
            <div className="col-md-8 col-lg-6">
              <div
                className={`${commonHeadingStyles.commonBoldHighlightedHeading} mb-2`}
              >
                {Strings?.reviewPersonalDetails}
              </div>
              <div className={micsStyles.customLine}></div>
            </div>
          </div>
        )}
        <div className="gap-4 mb-3">
          {renderField(
            Strings.reviewTitle,
            editFlags.title,
            title,
            ReviewFieldEnum.TITLE,
            ReviewFieldEnum.TITLE,
            [ReviewFieldEnum.TITLE]
          )}
          {renderField(
            Strings.reviewName,
            editFlags.name,
            `${firstName} ${middleName ?? ""} ${lastName}`,
            ReviewFieldEnum.NAME,
            ReviewFieldEnum.NAME,
            [
              ReviewNameFieldEnum.FIRST_NAME,
              ReviewNameFieldEnum.MIDDLE_NAME,
              ReviewNameFieldEnum.LAST_NAME,
            ]
          )}
          {renderField(
            Strings.dateOfBirth,
            editFlags.dateOfBirth,
            dateOfBirthValue?.split("-").reverse().join("/"),
            ReviewFieldEnum.DATE_OF_BIRTH,
            ReviewFieldEnum.DATE_OF_BIRTH,
            [ReviewFieldEnum.DATE_OF_BIRTH]
          )}
          {isAddressRequired &&
            renderField(
              Strings.address,
              editFlags.address,
              viewAddressData,
              ReviewFieldEnum.ADDRESS,
              ReviewFieldEnum.ADDRESS,
              [
                AddressFieldEnum.ADDRESS_ONE,
                AddressFieldEnum.ADDRESS_TWO,
                AddressFieldEnum.ADDRESS_THREE,
                AddressFieldEnum.ADDRESS_FOUR,
                AddressFieldEnum.POST_TOWN,
                AddressFieldEnum.POST_CODE,
              ]
            )}
        </div>
        <GenericViewFormComponent formData={personalDetailsReadData} />
      </div>
    </div>
  );
};
export default PersonalDetailsReviewComponent;
