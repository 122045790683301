import React, { useState } from "react";
import { RadioButton } from "../../common/basic components/radioButton";
import { INITIAL_COUNT } from "../../common/constant/customConstant";
import styles from "../../styleModules/reviewStepper.module.scss";
import Strings from "../../theme/string";
import { EditButtonComponent } from "../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { SaveButtonComponent } from "../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";

export const HelperRadioButtonLinkBankAccount = (props: any) => {
  const {
    mainObj,
    stepperDetails,
    label,
    fieldName,
    editFlag,
    dispatching,
    id,
    updateInput,
    className,
  } = props;

  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const INTEREST_RADIO_CONST = [
    `${Strings.interestPaidOption1} ${id}`,
    Strings.interestRadioLinkedAccount,
  ];

  const enableNdDisableField = () => {
    if (dataSaved && isEditEnabled && editFlag) return true;
    if (dataSaved) return true;
    if (editFlag && !isEditEnabled) return true;
    if (dataSaved && editFlag) return true;
  };

  const editModeHandler = () => {
    setIsEditEnabled(true);
  };

  const saveModeHandler = () => {
    setIsEditEnabled(false);
    setDataSaved(false);
    updateInput();
  };

  return (
    <div>
      {editFlag ? (
        <div className="d-flex align-items-center gap-3 mb-6">
          <div>
            <label className="form-label">
              {label}
              <span className="text-danger"> * </span>
            </label>
          </div>
          {isEditEnabled && (
            <SaveButtonComponent onClick={() => saveModeHandler()} />
          )}
        </div>
      ) : (
        <p className="new-line lh-base">{label}</p>
      )}

      {enableNdDisableField() && (
        <div className={"mb-6" + className}>
          <div className="d-flex">
            <div
              id={`formField-${fieldName}`}
              className={styles.genericFormFieldStyle}
              aria-label={`${fieldName} field`}
            >
              <div className={styles.genericFormFieldTextStyle}>
                {mainObj?.[fieldName]?.value}
              </div>
            </div>
            <EditButtonComponent onClick={() => editModeHandler()} />
          </div>
        </div>
      )}

      {!enableNdDisableField() && (
        <div className="row mb-3 gap-4 mt-4">
          <div className={"mb-6" + className}>
            {INTEREST_RADIO_CONST.map((item: any, key: number) => {
              return (
                <div
                  key={item}
                  className={`terms-conditions-choosePlan ${
                    key == INITIAL_COUNT && "mb-3"
                  }`}
                >
                  <RadioButton
                    onChange={() => dispatching(item)}
                    label={item}
                    inputClassName={"checkbox-width-zero"}
                    name="SelectInterestPaidAccountType"
                    isChecked={
                      mainObj?.interestBeingPaid?.value?.toLowerCase() ==
                      item?.toLowerCase()
                    }
                    key={key}
                  />
                </div>
              );
            })}
            {stepperDetails?.buttonValidatonIsRequired &&
              !mainObj?.interestBeingPaid?.value && (
                <div className="fst-italic font-size-12px text-danger">
                  {Strings.pleaseSelectAnyValue}
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};
