import React, { useState } from "react";
import SearchCheckBox from "../../../common/components/searchCheckBox";
import { INITIAL_COUNT } from "../../../common/constant/customConstant";
import { updateCitizenAndTax } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../../redux/redux-hooks";
import styles from "../../../styleModules/reviewStepper.module.scss";
import Strings from "../../../theme/string";
import { EditButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/editButtonComponent";
import { SaveButtonComponent } from "../../choosePlan/ReviewStepper/ReviewSubComponents/miscComponents/saveButtonComponent";
import StringData from "../../choosePlan/stringCitizenShipNdTaxInformation";

export const CitizenShipDisplay = (props: any) => {
  const {
    mainObj,
    editFlag,
    Country,
    stepperDetails,
    isHeadingRequired,
    isEditBtnRequired,
    heading,
  } = props;
  const dispatch = useAppDispatch();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);

  const setIsUkCitizenOnlyHandler = (tmpVal: string, saved: boolean) => {
    const key = Strings.isUKCitizenOnly;
    const obj = valueUpdateInObj(tmpVal, Strings.isUKCitizenOnly, saved);
    dispatch<any>(
      updateCitizenAndTax({
        obj: { ...obj },
        fieldName: key,
      })
    );
  };

  const valueUpdateInObj = (
    tmpVal: string,
    key: string,
    saved: boolean = false
  ) => {
    if (saved && editFlag) {
      return {
        value: mainObj?.[key]?.draftValue,
        draftValue: mainObj?.[key]?.draftValue,
      };
    } else if (editFlag) {
      return { draftValue: tmpVal, value: mainObj?.[key]?.value };
    } else return { value: tmpVal };
  };

  const getValueBtwDraftNdValue = (key: string) => {
    if (mainObj?.[key]?.draftValue) return mainObj?.[key]?.draftValue;
    else return mainObj?.[key]?.value;
  };

  const setOtherThanUKCitizenHandler = (
    item: any,
    tmpFieldName: string,
    saved: boolean
  ) => {
    const key = tmpFieldName;
    const obj = valueUpdateInObj(item, tmpFieldName, false);
    dispatch<any>(
      updateCitizenAndTax({
        obj: {
          isValidate: item?.length > INITIAL_COUNT ? true : false,
          ...obj,
        },
        fieldName: tmpFieldName,
      })
    );
  };

  const editModeHandler = () => {
    setDataSaved(!dataSaved);
    setIsEditEnabled(!isEditEnabled);
  };

  const enableNdDisableField = () => {
    if (dataSaved && isEditEnabled && editFlag) return false;
    if (dataSaved) return true;
    if (editFlag && !isEditEnabled) return true;
    if (dataSaved && editFlag) return true;
  };

  const saveModeHandler = () => {
    setIsUkCitizenOnlyHandler(
      mainObj?.[Strings.isUKCitizenOnly]?.draftValue,
      true
    );
    if (mainObj?.[Strings.isUKCitizenOnly]?.draftValue == Strings.yes)
      setOtherThanUKCitizenHandler(
        mainObj?.[Strings.otherThanUKCitizen]?.draftValue,
        Strings.otherThanUKCitizen,
        true
      );
    setDataSaved(true);
    setIsEditEnabled(false);
  };

  return (
    <>
      {editFlag && (
        <div className="d-flex align-items-center gap-3 mb-3">
          {isHeadingRequired && (
            <div className={styles.informationTitleHeading}>{heading}</div>
          )}
        </div>
      )}

      {editFlag && (
        <div className="row mb-3">
          <div className="d-flex col-md-8 col-lg-6 align-items-center gap-4">
            <div>
              <label className="form-label">
                {Strings.nationalitiesNdCitizenship}
                <span className="text-danger"> * </span>
              </label>
            </div>
            {isEditEnabled && (
              <div className="mb-3">
                <SaveButtonComponent onClick={() => editModeHandler()} />
              </div>
            )}
          </div>
        </div>
      )}

      {enableNdDisableField() && (
        <div className="col-md-8 col-lg-6 mb-3">
          <div className="d-flex">
            <div
              id={`formField-isUKCountry`}
              className={styles.genericFormFieldStyle}
              aria-label={`isUKCountry field`}
            >
              <div className={styles.genericFormFieldTextStyle}>
                {getValueBtwDraftNdValue(Strings.otherThanUKCitizen)?.map(
                  (item: any) => item["label"] + " "
                )}
              </div>
            </div>
            {isEditBtnRequired && (
              <EditButtonComponent onClick={() => editModeHandler()} />
            )}
          </div>
        </div>
      )}

      {!enableNdDisableField() && (
        <div className="row mb-3">
          <div className="col-md-8 col-lg-6">
            <label className="form-label">
              {StringData.selectOtherCountry}{" "}
              <span className="text-danger"> * </span>
            </label>
            <SearchCheckBox
              options={Country}
              setSelectedOptions={(item: any) =>
                setOtherThanUKCitizenHandler(
                  item,
                  Strings.otherThanUKCitizen,
                  false
                )
              }
              selectedOptions={getValueBtwDraftNdValue(
                Strings.otherThanUKCitizen
              )}
            />
          </div>
        </div>
      )}
      {stepperDetails?.buttonValidatonIsRequired &&
        !mainObj?.otherThanUKCitizen?.isValidate && (
          <div className="text-danger">{Strings.defaultFieldCanNotBeBlank}</div>
        )}
    </>
  );
};
