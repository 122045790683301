import React, { useEffect, useMemo, useState } from "react";
import { CustomModal } from "../../common/components/customModal";
import OTPInput from "../../common/components/otpInput";
import { EmailValidationPopup } from "../../common/components/stepper/emailValidationPopup";
import { SuggestionInput } from "../../common/components/suggestionInput";
import { updateUserDetails } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import DesignationField from "./personalInformationFields/designationField";
import DobField from "./personalInformationFields/dobField";
import AddressField from "./personalInformationFields/addressField";
import NameField from "./personalInformationFields/nameField";
import { ValidationInput } from "./validationInput";

export const StepperPersonalInformation = (props: any) => {
  const dispatch = useAppDispatch();
  const [spanVal, setSpanVal] = useState(Strings.addItManually);
  const [enableEmailValidationPopup, setEnableEmailValidationPopup] =
    useState(false);
  const [isEmailHasConfirmed, setIsEmailHasConfirmed] = useState(false);
  const [enableMobileOtpPopup, setEnableMobileOtpPopup] = useState(false);
  const [isMobileNumberConfirmed, setIsMobileNumberConfirmed] = useState(false);

  const stepperDetails: any = useAppSelector(
    (state: any) => state.stepperDetails
  );
  const data = stepperDetails?.stepperUserDetails;

  const updateState = (obj: any) => {
    dispatch<any>(updateUserDetails(obj));
  };

  useEffect(() => {
    if (!data["email"]?.isValidate) setIsEmailHasConfirmed(false);
  }, [data["email"]?.isValidate]);

  const emailEditHandler = () => {
    setEnableEmailValidationPopup(!enableEmailValidationPopup);
    setIsEmailHasConfirmed(false);
    updateState({
      fieldName: "email",
      value: "",
      isValidate: false,
    });
  };

  const editMobileNumberHandler = () => {
    setEnableMobileOtpPopup(!enableMobileOtpPopup);
    setIsEmailHasConfirmed(false);
    updateState({
      fieldName: "mobileWithoutCode",
      value: "",
      isValidate: false,
    });
  };

  const emailConfirmationHandler = () => {
    setEnableEmailValidationPopup(!enableEmailValidationPopup);
    setIsEmailHasConfirmed(true);
  };

  const emailButtonValidationHandler = () => {
    if (!data["email"]?.isValidate) return true;
    else if (data["email"]?.isValidate && isEmailHasConfirmed) return true;
    else return false;
  };

  const titleJsx = useMemo(
    () => (
      <div className="row mb-3">
        <div className="col-md-8 col-lg-6">
          <DesignationField
            stepperDetails={stepperDetails}
            reviewFlag={false}
          />
        </div>
      </div>
    ),
    [data["title"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const firstNameJsx = useMemo(
    () => (
      <div className="row mb-3">
        <NameField stepperDetails={stepperDetails} fieldName="firstName" />
      </div>
    ),
    [data["firstName"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const middleNameJsx = useMemo(
    () => (
      <div className="row mb-3">
        <NameField stepperDetails={stepperDetails} fieldName="middleName" />
      </div>
    ),
    [data["middleName"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const lastNameJsx = useMemo(
    () => (
      <div className="row mb-3">
        <NameField stepperDetails={stepperDetails} fieldName="lastName" />
      </div>
    ),
    [data["lastName"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const dataOfBirthJsx = useMemo(
    () => (
      <div className="row mb-3">
        <DobField stepperDetails={stepperDetails} reviewFlag={false} />
      </div>
    ),
    [data["dateOfBirth"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const emailAddressJsx = (
    <div className="row mb-3">
      <ValidationInput
        lableValue={Strings.emailAddress}
        labelDangerRequired={true}
        placeHolder={Strings.emailAddressPlaceholder}
        isBottomText={true}
        isButton={true}
        bottomText={Strings.emailAddressBottomText}
        type={Strings.defaultInputType}
        errorMsg={Strings.emailAddressErrorMsg}
        regExp={Strings.emailAddressRegex}
        buttonValidate={emailButtonValidationHandler()}
        isError={
          stepperDetails.buttonValidatonIsRequired && !data["email"]?.isValidate
        }
        id="email"
        value={data["email"]?.value}
        blankErrorMsg={Strings.emailAddressBlankErrorMsg}
        buttonText={Strings.emailAddressButtonText}
        spanclassName="fst-italic font-size-12px"
        confirmationClick={() =>
          setEnableEmailValidationPopup(!enableEmailValidationPopup)
        }
        isConfirm={data["email"]?.isValidate && isEmailHasConfirmed}
      />
    </div>
  );

  const mobileNumberJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.mobileNumber}
          labelDangerRequired={true}
          placeHolder={Strings.mobileNumberPlaceholder}
          isBottomText={true}
          isButton={true}
          maxLength={10}
          bottomText={Strings.mobileNumberBottomText}
          regExp={Strings.mobileNumberRegex}
          type={Strings.defaultInputType}
          errorMsg={Strings.mobileNumberErrorMsg}
          buttonValidate={!data["mobileWithoutCode"]?.isValidate}
          isError={
            stepperDetails.buttonValidatonIsRequired &&
            !data["mobileWithoutCode"]?.isValidate
          }
          confirmationClick={() => {
            setEnableMobileOtpPopup(!enableMobileOtpPopup);
            setIsMobileNumberConfirmed(true);
          }}
          isConfirm={
            data["mobileWithoutCode"]?.isValidate && isMobileNumberConfirmed
          }
          id="mobileWithoutCode"
          value={data["mobileWithoutCode"]?.value}
          blankErrorMsg={Strings.mobieNumberCanNotBeBlank}
          buttonText={Strings.mobileNumberButtonText}
          spanclassName="fst-italic font-size-12px"
          isPrefixRequired={true}
          prefixeValue={Strings.mobileCountryCodeIncludeZero}
        />
      </div>
    ),
    [
      data["mobileWithoutCode"]?.value,
      stepperDetails.buttonValidatonIsRequired,
      isMobileNumberConfirmed,
    ]
  );

  return (
    <>
      {enableEmailValidationPopup && (
        <CustomModal
          show={enableEmailValidationPopup}
          title={"Email Confirmation"}
          onClick={() =>
            setEnableEmailValidationPopup(!enableEmailValidationPopup)
          }
          child={<EmailValidationPopup email={data["email"]?.value} />}
          buttonVal={[
            {
              value: "Edit",
              className: "btn btn-md btn-primary_one-outlined me-2",
              onClick: () => emailEditHandler(),
              size: "sm",
            },
            {
              value: "Confirm",
              className: "btn btn-md btn-primary_one",
              onClick: () => emailConfirmationHandler(),
              size: "sm",
            },
          ]}
          isButton={true}
          size="md"
        />
      )}
      {enableMobileOtpPopup && (
        <CustomModal
          show={enableMobileOtpPopup}
          title={"We’ve sent you verification code"}
          onClick={() => setEnableMobileOtpPopup(!enableMobileOtpPopup)}
          child={
            <OTPInput
              mobileNumber={data["mobileWithoutCode"]?.value}
              onClick={editMobileNumberHandler}
            />
          }
          buttonVal={[
            {
              value: "Submit",
              className: "btn btn-md btn-primary_one",
              onClick: () => {
                setEnableMobileOtpPopup(false);
                setIsMobileNumberConfirmed(true);
              },
              size: "sm",
            },
          ]}
          isButton={true}
          size="md"
        />
      )}
      <div className="wizard_taxcitizenship">
        {titleJsx}
        {firstNameJsx}
        {middleNameJsx}
        {lastNameJsx}
        {dataOfBirthJsx}
        {emailAddressJsx}
        {mobileNumberJsx}
        <AddressField stepperDetails={stepperDetails} reviewFlag={false} />
      </div>
    </>
  );
};
