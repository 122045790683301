import { useEffect, useState } from "react";
import LoadingModal from "../../common/components/LoadingModal";
import MarketingPreferences from "../../common/components/MarketingPreferences";
import ProfileAvatar from "../../common/components/ProfileAvatar";
import {
  PartyAddress,
  PartyDetailsResponseTypes,
} from "../../interfaces/partyDetails.interface";
import {
  updateUserDetails,
  updatedLinkUKBank,
} from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../redux/redux-hooks";
import { getPartyDetailsApiData } from "../../services/profileSectionApis";
import { FETCH_FINANCIAL_DATA, GET_PARTY_DETAILS } from "../../services/url";
import headingStyles from "../../styleModules/commonHeading.module.scss";
import stylesMemo from "../../styleModules/memorableWord.module.scss";
import styles from "../../styleModules/profileStyles.module.scss";
import Strings from "../../theme/string";
import PersonalDetailsReviewComponent from "../choosePlan/ReviewStepper/ReviewSubComponents/PersonalDetailsReviewComponent";
import { StepperCitizenShipNdTaxInfromation } from "../choosePlan/stepperCitizenShipNdTaxInfromation";
import ProfileAddressParentComponent from "./profileAddressComponents/profileAddressParentComponent";
import Breadcrumbs from "../../common/basic components/Breadcrumbs";
import { setInitialPreferences } from "../../redux/reducer/marketingPrefencesSlice";
import ProfileLinkedAccountParentComponent from "./profileLinkedAccountComponent/profileLinkedAccountParentComponent";
import { fetchFinancialData } from "../../services/fetchFinancialAccountData";
import { extractSortCodeAndAccount } from "../../common/utils/extractSortCodeAndAcc";

const ProfileComponent = () => {
  const [loadModalFlag, setLoadModalFlag] = useState(false);
  const [partyData, setPartyData] = useState<PartyDetailsResponseTypes | null>(
    null
  );
  const [addressDetails, setAddressDetails] = useState<PartyAddress | null>(
    null
  );
  const [callComponent, setCallCompoent] = useState(false);

  const dispatch = useAppDispatch();


  const updateState = (obj: any) => {
    dispatch<any>(updateUserDetails(obj));
  };



  const dispatchLinkedAccountDetails = (linkedAccountDetails: any) => {
    const { sortCode, accountNumber: linkedAccNum } =
      extractSortCodeAndAccount(linkedAccountDetails || "") || {};
    const fields = [
      { field: "accountNumber", value: linkedAccNum },
      { field: "sortCode", value: sortCode },
    ];
    fields.forEach(({ field, value }) => {
      dispatch(
        updatedLinkUKBank({
          fieldName: field,
          value: value || "",
          draftValue: value || "",
          isValidate: true,
          isdraftValidate: true,
        })
      );
    });
  };
  useEffect(() => {
    (async () => {
      try {
        setLoadModalFlag(true);
        const token = localStorage.getItem("tokenVal") ?? "";
        if (token) {
          try {
            let partyDataResponse: PartyDetailsResponseTypes | null =
              await getPartyDetailsApiData(GET_PARTY_DETAILS, token);
            if (partyDataResponse && typeof partyDataResponse === "object") {
              dispathOtherDetails(partyDataResponse?.data?.partyDetails);
              setPartyData(partyDataResponse);
              const address =
                partyDataResponse?.data?.partyDetails?.addressList[0];
              setAddressDetails(address);
              dispatchAddressFields(address);
              dispatchLinkedAccountDetails(
                partyDataResponse?.data?.partyDetails
              );
              setLoadModalFlag(false);
            } else {
              setLoadModalFlag(false);
            }
          } catch (error) {
            setLoadModalFlag(false);
          }

          try {
            let financialData = await fetchFinancialData(
              FETCH_FINANCIAL_DATA,
              token
            );
            dispatchLinkedAccountDetails(financialData?.data[0]?.linkedAccount);
          } catch (error) {
            console.error(error);
          }
        } else {
          setLoadModalFlag(false);
        }
      } catch (error) {
        setLoadModalFlag(false);
      }
    })();
  }, []);

  const dispathOtherDetails = (otherDetails: any) => {
    const arrToUpdate = [
      "title",
      "firstName",
      "middleName",
      "lastName",
      "birthDate",
      "email",
      "mobileWithoutCode",
    ];
    arrToUpdate.map((item: string) =>
      updateState({
        fieldName: item,
        value: otherDetails[item] || "",
        draftValue: otherDetails[item] || "",
        isValidate: true,
        isdraftValidate: true,
      })
    );
    setCallCompoent(true);
  };

  const dispatchAddressFields = (address: PartyAddress) => {
    const fields = [
      { field: "addressline1", value: address?.addressLine1 },
      { field: "addressline2", value: address?.addressLine2 },
      { field: "addressline3", value: address?.addressLine3 },
      { field: "addressline4", value: address?.addressLine4 },
      { field: "posttown", value: address?.city },
      { field: "postcode", value: address?.postalCode },
      { field: "county", value: address?.country },
    ];
    fields.forEach(({ field, value }) => {
      updateState({
        fieldName: field,
        value: value || "",
        draftValue: value || "",
        isValidate: true,
        isdraftValidate: true,
      });
    });
  };

  const marketingPrefdata = {
    smsOptIn: partyData?.data?.partyDetails?.smsOptIn,
    pushOptIn: partyData?.data?.partyDetails?.pushOptIn,
    emailOptIn: partyData?.data?.partyDetails?.emailOptIn,
    postOptIn: partyData?.data?.partyDetails?.postOptIn,
  };

  dispatch(setInitialPreferences(marketingPrefdata));

  const data = {
    accountName: Strings.myProfileText,
  };

  return (
    <>
      <LoadingModal show={loadModalFlag} size="sm" />
      <main className="mt-4 container">
        <header>
          <Breadcrumbs data={data} previousPath="/home" />
          <h1 className={`${headingStyles.headingMedium} mt-2`}>
            {Strings?.myProfileText}
          </h1>
          <div className="row mt-2">
            <div className="col-md-8 col-lg-6">
              <p className={styles.myProfileSubHeading}>
                {Strings.myProfileSubHeading}
              </p>
            </div>
          </div>
          <ProfileAvatar
            firstName={partyData?.data.partyDetails.firstName || ""}
            lastName={partyData?.data.partyDetails.lastName || ""}
          />
        </header>

        {callComponent && (
          <section>
              <p className={`mb-0 fs-18 mt-3 ${stylesMemo.marketingPrefLabel}`}>
                {Strings.personalInformationText}
              </p>
              <hr className="col-md-8 col-lg-6" />
              <PersonalDetailsReviewComponent
                isAddressRequired={false}
                isEditBtnRequired={false}
                isInformationTitleRequired={false}
              />
            </section>
        
        )}

        <section className="col-md-8 col-lg-6">
          {addressDetails && (
            <ProfileAddressParentComponent addressData={addressDetails} />
          )}
        </section>

        {callComponent && (
          <section>
              <p className={`mb-0 fs-18 mt-3 ${stylesMemo.marketingPrefLabel}`}>
                {Strings.myProfileNationalTaxHeading}
              </p>
              <hr className="col-md-8 col-lg-6" />
              <StepperCitizenShipNdTaxInfromation
                editFlag={true}
                isEditBtnRequired={false}
                isHeadingRequired={false}
              />
            </section>
        )
        }
        <section >
          <ProfileLinkedAccountParentComponent />
        </section>
        {callComponent && (
          
           <section className="col-md-8 col-lg-6 mt-3">
              <MarketingPreferences
                labels={Strings.pushNotification}
                apiOnToggle={true}
              />
            </section>
        )}
      </main>
    </>
  );
};

export default ProfileComponent;
