import React, { useState } from "react";
import Strings from "../../../theme/string";
import { Input } from "../../basic components/input";

export const TaxDisplayComponent = (props: any) => {
  const { label, placeholder, bottomText, value, updateCountryHandler } = props;
  const checkInputValue = (e: any) => {
    const tmpVal = e?.target?.value;
    const isValueNumeric = !isNaN(tmpVal);
    if (!isValueNumeric || tmpVal.length > 9) {
      const subStringVal = tmpVal?.substring(0, tmpVal?.length - 1);
      updateCountryHandler(subStringVal);
    } else {
      updateCountryHandler(tmpVal);
    }
  };

  return (
    <div className="row mb-3">
      <div className="col-md-8 col-lg-6">
        <label className="form-label">
          {label} <span className="text-danger"> * </span>
        </label>
        <Input
          labelClassName=""
          lableValue={label}
          labelDangerRequired={true}
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            checkInputValue(e)
          }
          className="form-control"
          spanclassName="fst-italic font-size-12px"
          bottomText={bottomText}
          bottomDiscription={true}
        />
      </div>
    </div>
  );
};
